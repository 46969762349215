import "App.css";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "routes";
import { useEffect } from "react";
import { Provider, useDispatch } from "react-redux";
import store from "store/store";
import { loginUser } from "store/authSlice";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    if (token) {
      dispatch(loginUser({ token })); // Dispatch action to restore user session
    }
  }, [dispatch]);

  return (
    <Provider store={store}>
      <Router>
        <AppRoutes />
      </Router>
    </Provider>
  );
}

export default App;
