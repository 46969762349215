import axios from "axios";
import apiUrls from "components/Constants/ApiUrls";
import { handleApiError } from "api/errorHandler";

//GET Development property list
export const getDevProperties = async () => {
  try {
    const response = await axios.get(apiUrls.devPropertiesList);
    return response.data;
  } catch (error) {
    const message = handleApiError(error);
    console.error(`Error fetching development properties: ${message}`);
    throw new Error(message);
  }
};

//GET Development property details
export const getDevPropertyDetails = async (id) => {
  try {
    const response = await axios.post(apiUrls.detailedProperty, { id });
    return response.data;
  } catch (error) {
    const message = handleApiError(error);
    console.error(`Error fetching property details: ${message}`);
    throw new Error(message);
  }
};

// GET Development Completed Projects List
export const getDevCompletedProperties = async () => {
  try {
    const response = await axios.get(apiUrls.devCompletedProperties);
    return response.data;
  } catch (error) {
    const message = handleApiError(error);
    console.error(`Error fetching completed properties: ${message}`);
    throw new Error(message);
  }
};

//GET Builders property list
export const getBuildProperties = async () => {
  try {
    const response = await axios.get(apiUrls.buildPropertiesList);
    return response.data;
  } catch (error) {
    const message = handleApiError(error);
    console.error(`Error fetching development properties: ${message}`);
    throw new Error(message);
  }
};

//GET Builders property details
export const getBuilderPropertyDetails = async (id) => {
  try {
    const response = await axios.post(apiUrls.detailedProperty, { id });
    return response.data;
  } catch (error) {
    const message = handleApiError(error);
    console.error(`Error fetching property details: ${message}`);
    throw new Error(message);
  }
};
