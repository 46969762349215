import React, { useState } from "react";
import Layout from "components/Layouts/LayoutPrimary/LayoutPrimary";
import Swal from "sweetalert2";
import "components/Contact/Contact.scss";
import contactbannerright from "assets/images/Contact/contactbannerright.png";
import contactform from "assets/images/Contact/contactform.png";
import Strings from "components/Constants/Strings";
import { submitContactForm } from "api/contactApi";
import { handleApiError } from "api/errorHandler";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    type: "",
    subType: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, email, phone, message, type, subType } =
      formData;

    // Validation
    if (
      !firstName ||
      !lastName ||
      !email ||
      !phone ||
      !message ||
      (type === "1" && !subType)
    ) {
      Swal.fire({
        title: "Error",
        text: "Please fill in all required fields.",
        icon: "error",
      });
      return;
    }

    setLoading(true);

    try {
      // Adjust the keys to match the backend JSON format
      const payload = {
        fname: firstName,
        lname: lastName,
        email,
        phone,
        message,
        interest: type,
        sub_interest: subType,
      };

      // Submit the transformed payload to the API function
      await submitContactForm(payload);

      // Redirect to the appropriate Microsoft Form
      if (type === "1") {
        const formUrl =
          subType === "1"
            ? Strings.individualInvestment
            : Strings.groupInvestment;

        window.open(formUrl, "_blank");
      }

      Swal.fire({
        title: "Success",
        text: "Your form has been submitted!",
        icon: "success",
      });

      // Reset Form after submission
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
        type: "",
        subType: "",
      });
    } catch (error) {
      const errorMessage = handleApiError(error);
      console.error("Error submitting form:", errorMessage);
      Swal.fire({
        title: "Error",
        text: errorMessage || "Something went wrong. Please try again later.",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <main className="contactpage">
        {/* Banner Section */}
        <section
          className="contactpagebanner"
          role="banner"
          aria-labelledby="contact-banner-title"
        >
            {Strings.contactUs}
            <div className="ms-auto d-none d-md-block">
              <img src={contactbannerright} alt="Contact banner right" />
            </div>
        </section>

        {/* Form Section */}
        <section className="p-5">
          <div className="container">
            <div className="contactpageform">
              <div
                className="contactpageformhead"
                aria-labelledby="contactPageFormHead"
              >
                {Strings.contactPageFormHeadOne}{" "}
                <span>{Strings.contactPageFormHeadTwo}</span>
              </div>
              <div className="row">
                <div className="col-md-5 col-12">
                  <form
                    onSubmit={handleSubmit}
                    aria-labelledby="contactPageHeader"
                    aria-describedby="contactFormDesc"
                  >
                    <fieldset>
                      <legend className="visually-hidden">
                        Contact Us Form
                      </legend>
                      <div id="contactFormDesc" className="visually-hidden">
                        Fill out the fields below to submit your query.
                      </div>
                      <div className="mb-3 mt-4">
                        <label htmlFor="firstName" className="form-label">
                          {Strings.firstName} <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="firstName"
                          name="firstName"
                          className="form-control"
                          value={formData.firstName}
                          onChange={handleChange}
                          required
                          aria-required="true"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="lastName" className="form-label">
                          {Strings.lastName} <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="lastName"
                          name="lastName"
                          className="form-control"
                          value={formData.lastName}
                          onChange={handleChange}
                          required
                          aria-required="true"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          {Strings.emailAddr} <span>*</span>
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          value={formData.email}
                          onChange={handleChange}
                          required
                          aria-required="true"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="phone" className="form-label">
                          {Strings.phone} <span>*</span>
                        </label>
                        <input
                          type="number"
                          id="phone"
                          name="phone"
                          className="form-control"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                          aria-required="true"
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="type" className="form-label">
                          {Strings.type}
                        </label>
                        <select
                          id="type"
                          name="type"
                          className="form-select"
                          value={formData.type}
                          onChange={handleChange}
                        >
                          <option value="">Select an option</option>
                          {Strings.typeOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* Numeric value as string comparison for Investment Opportunities */}

                      {formData.type === "1" && (
                        <div className="mb-3">
                          <label htmlFor="subType" className="form-label">
                            {Strings.subType}
                          </label>
                          <select
                            id="subType"
                            name="subType"
                            className="form-select"
                            value={formData.subType}
                            onChange={handleChange}
                          >
                            <option value="">Select an option</option>
                            {Strings.subTypeOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                            {/* <option value="Individual Investor">
                              {Strings.subType1}
                            </option>
                            <option value="Group Investment">
                              {Strings.subType2}
                            </option> */}
                          </select>
                        </div>
                      )}

                      <div className="mb-3">
                        <label htmlFor="message" className="form-label">
                          {Strings.message}
                        </label>
                        <textarea
                          id="message"
                          name="message"
                          className="form-control"
                          rows="3"
                          value={formData.message}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </fieldset>

                    <div className="mt-4">
                      <button
                        type="submit"
                        className="btn btn-primary w-100"
                        disabled={loading}
                        aria-busy={loading ? "true" : "false"}
                      >
                        {loading ? Strings.submitting : Strings.getMoreInfo}
                        {/* <i className="spinner-border spinner-border-sm"></i> */}
                      </button>
                    </div>
                  </form>
                </div>
                <div className="col-md-7 col-12 d-none d-md-block">
                  <div className="contactpageformimg">
                    <img src={contactform} alt="Contact form" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Contact;
