import LayoutSecondary from "components/Layouts/LayoutSecondary/LayoutSecondary";
import React from "react";

const RentalProperty = () => {
  return (
    <LayoutSecondary>
      <div className="container-fluid min-vh-100 d-flex flex-column justify-content-center align-items-center bg-light">
      <div className="text-center">
        <h1 className="display-5 text-success">Welcome to Rental Property</h1>
        <p className="lead">We are sorry, but there is no data available at the moment.</p>
        <p className="text-muted">Please check back later or contact us for more information.</p>
      </div>
      </div>
    </LayoutSecondary>
  );
};

export default RentalProperty;
