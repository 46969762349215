import React, { useState, useEffect } from "react";
import Layout from "components/Layouts/LayoutPrimary/LayoutPrimary";
import aboutusbannerright from "assets/images/About/aboutusbannerright.png";
import aboutusstory from "assets/images/Careers/storyleft.png";
import "components/Careers/Careers.scss";
import ApplyJobForm from "components/Careers/ApplyJobForm";
import Strings from "components/Constants/Strings";

const jobListings = [
  // {
  //   id: 1,
  //   title: "Project Manager",
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  // },
  // {
  //   id: 2,
  //   title: "Software Engineer",
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  // },
  // {
  //   id: 3,
  //   title: "Tech Lead",
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit,sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  // },
];

const Careers = () => {
  const [selectedJob, setSelectedJob] = useState(null);

  // Function to open the modal with the selected Job's details
  const openModal = (jobId) => {
    setSelectedJob(jobId);
  };

  // Handle modal show behavior using useEffect
  useEffect(() => {
    if (selectedJob) {
      const modalElement = document.getElementById("applyjob");

      // Only show the modal if it exists and selectedMember is set
      if (modalElement) {
        const modal = new window.bootstrap.Modal(modalElement);
        modal.show(); // Show the modal
      }
    }
  }, [selectedJob]); // This effect runs only when selectedMember changes

  // Function to close the modal
  const closeModal = () => {
    setSelectedJob(null); // Reset selected job
  };

  return (
    <Layout>
      <main className="careerpage">
        {/* Banner Section */}
        <section
          className="careerpagebanner"
          role="banner"
          aria-labelledby="career-banner-title"
        >
          {Strings.careers}
          <div className="ms-auto d-none d-md-block">
            <img src={aboutusbannerright} alt="Careers banner right" />
          </div>
        </section>

        {/* Story Section */}
        <section
          className="careerpagestory"
          aria-labelledby="career-story-title"
        >
          <div className="row align-items-center justify-content-center">
            <div className="col text-center">
              <div className="careerpagestoryimgright">
                <img src={aboutusstory} alt="Career page story" />
              </div>
            </div>
            <div className="col">
              <div className="careerpagestoryhead">
                 {Strings.discoverTheOpportunityOne}{" "}
                <span>{Strings.discoverTheOpportunityTwo}</span>
              </div>
              <div className="careerpagestorydesc">
                {Strings.discoverTheOpportunityDesc}
              </div>
            </div>
          </div>
        </section>

        {/* Talent Community Section */}
        <section
          className="careerpagetalent"
          aria-labelledby="career-talent-title"
        >
          <div className="careerpagetalenthead">
            {Strings.joinOurTalentCommunityOne}{" "}
            <span>{Strings.joinOurTalentCommunityTwo}</span>
          </div>
          <div className="row">
            {/* Check if there are no job listings */}
            {jobListings.length === 0 ? (
              <div className="col-12 text-center text-body">
                <p>
                  No positions available at this time, please check back soon.
                </p>
              </div>
            ) : (
              jobListings.map((job) => (
                <div
                  className="col-4"
                  key={job.id}
                  aria-labelledby={`job-title-${job.id}`}
                >
                  <div className="careerpagetalentcard">
                    <div className="careerpagetalentcardhead">{job.title}</div>
                    <div className="careerpagetalentcarddata">
                      {job.description}
                    </div>
                    <div className="careerpagetalentcardbtn">
                      <button
                        type="button"
                        className="btn btn-primary"
                        aria-label={`Apply for ${job.title}`}
                        onClick={() => openModal(job.id)}
                      >
                        {Strings.apply}
                      </button>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </section>

        {/* Apply Job Modal */}
        {selectedJob && (
          <ApplyJobForm jobId={selectedJob} onClose={closeModal} />
        )}
      </main>
    </Layout>
  );
};

export default Careers;
