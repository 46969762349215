import React, { useState } from "react";
import Swal from "sweetalert2";
import Strings from "components/Constants/Strings";
//import axios from "axios";

const ApplyJobForm = ({ jobId, onClose }) => {
  const [formData, setFormData] = useState({
    resume: null,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    jobTitle: "",
    location: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      resume: e.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    // Handle form submission logic here

    const formDataToSend = new FormData();
    formDataToSend.append("resume", formData.resume);
    formDataToSend.append("firstName", formData.firstName);
    formDataToSend.append("lastName", formData.lastName);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append("jobTitle", formData.jobTitle);
    formDataToSend.append("location", formData.location);
    formDataToSend.append("jobId", jobId);

    try {
      console.log("Form submitted:", formData);

      Swal.fire("Success", "Your application has been submitted!", "success");

      // Close the modal after submission
      onClose();

      // const response = await axios.post("", formDataToSend, {
      //   headers: { "Content-Type": "multipart/form-data" },
      // });

      // if (response.status === 200) {
      //   Swal.fire("Success", "Your application has been submitted!", "success");
      //   onClose(); // Close the popup after successful submission
      // }
    } catch (error) {
      Swal.fire(
        "Error",
        "Failed to submit your application. Try again.",
        "error"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className="modal fade"
      id="applyjob"
      aria-labelledby="applyjobLabel"
      aria-hidden="false"
      tabIndex={-1}
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="applyjob1Label">
              {Strings.joinOurTalentCommunity}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col">
                <form onSubmit={handleSubmit} aria-describedby="applyjobDesc">
                  <fieldset>
                    <legend className="visually-hidden">
                      Job Application Form
                    </legend>
                    <label htmlFor="resume" className="form-label">
                      {Strings.uploadResume} <span>*</span>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="file"
                        id="resume"
                        className="form-control"
                        onChange={handleFileChange}
                        required
                        aria-required="true"
                      />
                      <label className="input-group-text">
                        {Strings.uploadButton}
                      </label>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="firstName" className="form-label">
                        {Strings.firstName}
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        className="form-control"
                        onChange={handleInputChange}
                        required
                        aria-required="true"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="lastName" className="form-label">
                        {Strings.lastName}
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        className="form-control"
                        onChange={handleInputChange}
                        required
                        aria-required="true"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        {Strings.emailAddr}
                        <span>*</span>
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        onChange={handleInputChange}
                        required
                        aria-required="true"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="phone" className="form-label">
                        {Strings.phone}
                        <span>*</span>
                      </label>
                      <input
                        type="number"
                        id="phone"
                        name="phone"
                        className="form-control"
                        onChange={handleInputChange}
                        required
                        aria-required="true"
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="jobTitle" className="form-label">
                        {Strings.jobTitle}
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        id="jobTitle"
                        name="jobTitle"
                        className="form-control"
                        onChange={handleInputChange}
                        required
                        aria-required="true"
                      />
                    </div>
                    <div>
                      <label htmlFor="location" className="form-label">
                        {Strings.location}
                        <span>*</span>
                      </label>
                      <input
                        type="text"
                        id="location"
                        name="location"
                        className="form-control"
                        onChange={handleInputChange}
                        required
                        aria-required="true"
                      />
                    </div>
                  </fieldset>

                  <div className="mt-4 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary applybtn"
                      disabled={isSubmitting}
                      aria-busy={isSubmitting ? "true" : "false"}
                    >
                      {isSubmitting ? "Submitting..." : "Apply"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyJobForm;
