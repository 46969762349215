import React from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "routes/protectedRoute";
import Home from "components/Home/Home";
import Development from "components/Development/Development";
import Builders from "components/Builders/Builders";
import PropertyManagement from "components/PropertyManagement/PropertyManagement";
import About from "components/About/About";
import Careers from "components/Careers/Careers";
import Contact from "components/Contact/Contact";
import PropertyList from "components/Development/PropertyList/PropertyList";
import PropertyDetails from "components/Development/PropertyDetails/PropertyDetails";
import ProjectList from "components/Builders/ProjectList/ProjectList";
import ProjectDetails from "components/Builders/ProjectDetails/ProjectDetails";
import RentalProperty from "components/PropertyManagement/RentalProperty/RentalProperty";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

const AppRoutes = () => (
  <ErrorBoundary>
    <Routes>
      <Route path="/" element={<Home />} />

      <Route path="/development" element={<Development />} />

      <Route path="/builders" element={<Builders />} />

      <Route path="/property-management" element={<PropertyManagement />} />

      <Route path="/about-us" element={<About />} />

      <Route path="/careers" element={<Careers />} />

      <Route path="/contact-us" element={<Contact />} />

      <Route
        path="/development/property-list"
        element={
          <ProtectedRoute path={"development"}>
            <PropertyList />
          </ProtectedRoute>
        }
      />

      <Route
        path="/development/property-list/property-details/:id"
        element={
          <ProtectedRoute path={"development"}>
            <PropertyDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/builders/property-list"
        element={
          <ProtectedRoute path={"builders"}>
            <ProjectList />
          </ProtectedRoute>
        }
      />

      <Route
        path="/builders/property-list/property-details/:id"
        element={
          <ProtectedRoute path={"builders"}>
            <ProjectDetails />
          </ProtectedRoute>
        }
      />

      <Route
        path="/property-management/rental-property"
        element={<RentalProperty />}
      />
    </Routes>
  </ErrorBoundary>
);

export default AppRoutes;
