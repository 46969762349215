import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "components/Builders/ProjectDetails/ProjectDetails.scss";
import LayoutSecondary from "components/Layouts/LayoutSecondary/LayoutSecondary";
import Image1 from "assets/images/Home/tour.png";
import Strings from "components/Constants/Strings";
import { getBuilderPropertyDetails } from "api/propertiesApi";
import { handleApiError } from "api/errorHandler";
import apiUrls from "components/Constants/ApiUrls";

// const properties = [
//   {
//     id: 1,
//     name: "Riverfront Development Project",
//     images: [Image1, Image1, Image1, Image1],
//   },
//   {
//     id: 2,
//     name: "Beachside Luxury Villa",
//     images: [Image1, Image1, Image1, Image1],
//   },
//   {
//     id: 3,
//     name: "Mountain Retreat",
//     images: [Image1, Image1, Image1, Image1],
//   },
//   {
//     id: 4,
//     name: "Urban Skyline",
//     images: [Image1, Image1, Image1, Image1],
//   },
// ];

const ProjectDetails = () => {
  const { id } = useParams();
  const [property, setProperty] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchPropertyDetails = async () => {
      try {
        const data = await getBuilderPropertyDetails(id);
        setProperty(data);
      } catch (err) {
        const errorMessage = handleApiError(err);
        setError(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPropertyDetails();
  }, [id]);

  if (isLoading) {
    return (
      <LayoutSecondary>
        <main className="projectdetails">
          <div className="loader d-flex justify-content-center align-items-center vh-100">
            <div className="text-center mb-0">
              <h1>{Strings.loading}</h1>
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </main>
      </LayoutSecondary>
    );
  }

  if (error) {
    return (
      <LayoutSecondary>
        <main className="projectdetails">
          <h1>{Strings.errorOccurred}</h1>
          <p>{error}</p>
        </main>
      </LayoutSecondary>
    );
  }

  if (!property) {
    return (
      <LayoutSecondary>
        <main className="projectdetails">
          <h1>{Strings.propertyNotFoundErrorMessage}</h1>
        </main>
      </LayoutSecondary>
    );
  }

  return (
    <LayoutSecondary>
      <main className="projectdetails">
        {/* Banner Section */}
        <section
          className="projectdetailsbanner"
          style={{
            backgroundImage: `url(${apiUrls.imagesURL}${property.filenames[0]})`,
          }}
          aria-labelledby="projectdetails-banner-main"
        >
          <div className="projectdetailsbanneroverlay">
            <div className="projectdetailsbanneroverlaydetails">
              <div className="projectdetailsbanneroverlaydetailsdata">
                <span className="projectdetailsbanneroverlaydetailsdatalabel">
                  {Strings.location}
                </span>
                <span className="projectdetailsbanneroverlaydetailsdatavalue">
                  {property.project_location_name}
                </span>
              </div>
              <div className="projectdetailsbanneroverlaydetailsdata">
                <span className="projectdetailsbanneroverlaydetailsdatalabel">
                  {Strings.projectSize}
                </span>
                <span className="projectdetailsbanneroverlaydetailsdatavalue">
                  {property.landArea}
                </span>
              </div>
              <div className="projectdetailsbanneroverlaydetailsdata">
                <span className="projectdetailsbanneroverlaydetailsdatalabel">
                  {Strings.projectDate}
                </span>
                <span className="projectdetailsbanneroverlaydetailsdatavalue">
                  {property.propertyDate}
                </span>
              </div>
              <div className="projectdetailsbanneroverlaydetailsdatabutton">
                <button className="projectdetailsbanneroverlaydetailsdatabuttondownload">
                  <i className="fa-solid fa-download"></i>
                  {Strings.downloadBrochure}
                </button>
              </div>
            </div>
          </div>
        </section>

        <section
          className="projectdetailsbody"
          aria-labelledby="projectdetails-body-main"
        >
          {/* Breadcrumb Section */}
          <div className="projectdetailsbodybreadcrumb">
            <Link to="/">{Strings.headerHome}</Link>
            <span className="projectdetailsbodybreadcrumbseparator"> / </span>
            <Link to="/builders">{Strings.buildersPage}</Link>
            <span className="projectdetailsbodybreadcrumbseparator"> / </span>
            <Link to="/builders/property-list">{Strings.propertyList}</Link>
            <span className="projectdetailsbodybreadcrumbseparator"> / </span>
            <Link to={`/builders/property-list/property-details/${id}`}>
              {Strings.propertyDetails}
            </Link>
          </div>

          {/* About Project Section */}
          <div className="projectdetailsbodyaboutproject">
            <h2 className="projectdetailsbodyaboutprojecthead">
              {Strings.aboutProject}
            </h2>
            <div>
              <p className="projectdetailsbodyaboutprojectdesc">
                {property.propertyDesc ? (
                  property.propertyDesc
                ) : (
                  <>
                    {Strings.loremOne} <br /> {Strings.loremTwo} <br />{" "}
                    {Strings.loremThree} <br /> {Strings.loremFour}
                  </>
                )}
              </p>

              <p>
                <strong>{Strings.locationAddress}:</strong>{" "}
                {property.project_location_address}
              </p>
              <p>
                <strong>{Strings.projectCategory}:</strong>{" "}
                {property.project_category_name}
              </p>
              <p>
                <strong>{Strings.projectType}:</strong>{" "}
                {property.project_type_name}
              </p>
            </div>

            {/* Back to List Link */}
            <div className="projectdetailsbodyaboutprojectbacktolist">
              <Link to="/builders/property-list">{Strings.backToList}</Link>
            </div>
            <div className="projectdetailsbodyaboutprojectheroimg">
              <img
                src={`${apiUrls.imagesURL}${property.filenames[0]}`}
                alt={`Property ${property.name}`}
                onError={(e) => {
                  e.target.src = Image1;
                }}
              />
            </div>
          </div>

          {/* Get more information section */}
          <div className="projectdetailsbodygetmoreinfo">
            <div className="projectdetailsbodygetmoreinfocontent">
              <span>{Strings.getMoreInfoAbout}</span>
              <div className="projectdetailsbodygetmoreinfocontentlinkbox">
                <Link to="/contact-us">{Strings.contactUs}</Link>
              </div>
            </div>
          </div>

          {/* Project Gallery */}
          <div className="projectdetailsbodygallery">
            <h2 className="projectdetailsbodygalleryhead">
              {Strings.projectGallery}
            </h2>
            <div className="projectdetailsbodygallerygrid">
              {/* Images dynamically rendered here */}
              {property.filenames &&
                property.filenames.map((image, index) => (
                  <div
                    key={index}
                    className="projectdetailsbodygallerygriditem"
                    onClick={() =>
                      setSelectedImage(`${apiUrls.imagesURL}${image}`)
                    }
                  >
                    <img
                      src={`${apiUrls.imagesURL}${image}`}
                      alt={`Property ${property.name}`}
                      onError={(e) => {
                        e.target.src = Image1;
                      }}
                    />
                    <button className="projectdetailsbodygallerygriditemviewbutton">
                      {Strings.view}
                    </button>
                  </div>
                ))}
            </div>

            {/* Fullscreen Modal */}
            {selectedImage && (
              <div
                className="projectdetailsbodygalleryfullscreenmodal"
                onClick={() => setSelectedImage(null)}
              >
                {/* Close Icon */}
                <button>
                  <i
                    className="fas fa-times"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent closing when clicking the icon itself
                      setSelectedImage(null);
                    }}
                  ></i>
                </button>

                <img src={selectedImage} alt="Fullscreen view" />
              </div>
            )}
          </div>
        </section>
      </main>
    </LayoutSecondary>
  );
};

export default ProjectDetails;
