// contactApi.js
import axios from "axios";
import apiUrls from "components/Constants/ApiUrls";
import Strings from "components/Constants/Strings";
import { handleApiError } from "api/errorHandler";

// Submit contact form data to the backend
export const submitContactForm = async (formData) => {
  try {
    const response = await axios.post(apiUrls.contactFormSubmit, formData);
    console.log("Form data submitted:", formData);
    return response.data;
  } catch (error) {
    const message = handleApiError(error);
    console.error("Failed to submit contact form:", message);
    throw new Error(message || Strings.failedToSubmit);
  }
};
