import { useEffect } from "react";
import { useSelector } from "react-redux";
//import Swal from "sweetalert2";
import { useNavigate, useLocation } from "react-router-dom";
import { selectIsLoggedIn } from "store/authSlice";

const ProtectedRoute = ({ children, path }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoggedIn) {
      const redirectTo = `/${path}` || "/";
      navigate(redirectTo, { replace: true });

      // Trigger SweetAlert if the user is not logged in
      // Swal.fire({
      //   title: "Access Denied",
      //   text: "Please log in first to access this page.",
      //   icon: "warning",
      //   confirmButtonText: "OK",
      // }).then(() => {
      //   const redirectTo = `/${path}`;
      //   navigate(redirectTo, { replace: true });
      // });
    }
  }, [isLoggedIn, location, navigate, path]);

  // Render protected content only if logged in
  return isLoggedIn ? children : null;
};

export default ProtectedRoute;
