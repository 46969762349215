import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectIsLoggedIn, selectShowDashboard } from "store/authSlice";
import Swal from "sweetalert2";
import logoGroup from "assets/images/Headers/logogroup.svg";
import SigninRegister from "components/SigninRegister/SigninRegister";
import Strings from "components/Constants/Strings";

const HeaderSecondary = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  //const { user } = useSelector((state) => state.auth);
  const showDashboardState = useSelector(selectShowDashboard);

  const handleLogout = () => {
    dispatch(logout());
    Swal.fire({
      title: "Success",
      text: "Logout successful!",
      icon: "success",
    }).then(() => {
      window.location.reload();
    });
  };

  return (
    <header>
      <nav
        className="navbar fixed-top px-4 navbar-expand-lg navbar-light bg-light"
        aria-label="Secondary Navigation"
      >
        <div className="container-fluid">
          <Link className="navbar-brand" to="/" aria-label="Home">
            <img
              src={logoGroup}
              alt=""
              className="d-inline-block align-text-top"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse flex-grow-0 navbar-collapse" id="navbarNav">
            <ul className="navbar-nav gap-5">
              <li className="nav-item">
                <Link
                  className={
                    location.pathname === "/" ? "nav-link active" : "nav-link"
                  }
                  aria-current={location.pathname === "/" ? "page" : undefined}
                  to="/"
                >
                  {Strings.headerHome}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    location.pathname === "/about-us"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  aria-current={
                    location.pathname === "/about-us" ? "page" : undefined
                  }
                  to="/about-us"
                >
                  {Strings.headerAboutUs}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    location.pathname === "/careers"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  aria-current={
                    location.pathname === "/careers" ? "page" : undefined
                  }
                  to="/careers"
                >
                  {Strings.headerCareers}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    location.pathname === "/contact-us"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  aria-current={
                    location.pathname === "/contact-us" ? "page" : undefined
                  }
                  to="/contact-us"
                >
                  {Strings.headerContactUs}
                </Link>
              </li>
              {isLoggedIn && (
                <li className="nav-item">
                  <Link
                    className={
                      location.pathname === showDashboardState
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current={
                      location.pathname === showDashboardState
                        ? "page"
                        : undefined
                    }
                    to={showDashboardState}
                  >
                    {Strings.headerDashboard}
                  </Link>
                </li>
              )}
              {isLoggedIn ? (
                <button
                  type="button"
                  className="logoutbtn btn btn-light"
                  onClick={handleLogout}
                >
                  {/* {user?.name || Strings.headerLogout} */}
                  {Strings.headerLogout}
                </button>
              ) : (
                <button
                  type="button"
                  className="loginbtn btn btn-light"
                  data-bs-toggle="modal"
                  data-bs-target="#signinModal"
                  aria-haspopup="dialog"
                  aria-controls="signinModal"
                >
                  {Strings.headerLogin}
                </button>
              )}
              <SigninRegister />
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default HeaderSecondary;
