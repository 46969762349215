import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "components/Development/PropertyList/PropertyList.scss";
import LayoutSecondary from "components/Layouts/LayoutSecondary/LayoutSecondary";
import defaultImage from "assets/images/Commonfiles/coming-soon.png";
import aboutUsBannerRight from "assets/images/About/aboutusbannerright.png";
import Strings from "components/Constants/Strings";
import { getDevProperties } from "api/propertiesApi";
import apiUrls from "components/Constants/ApiUrls";
import { handleApiError } from "api/errorHandler";

// Sample data for property cards with `type` field
// const sampleProperties = [
//   {
//     id: 1,
//     title: "Property 1",
//     image: Image1,
//     type: "",
//     price: "$3000",
//     location: "Kansas City",
//   },
//   {
//     id: 2,
//     title: "Property 2",
//     image: Image1,
//     type: "",
//     price: "$2500",
//     location: "Austin",
//   },
//   {
//     id: 3,
//     title: "Property 3",
//     image: Image1,
//     type: "",
//     price: "$3200",
//     location: "Seattle",
//   },
//   {
//     id: 4,
//     title: "Property 4",
//     image: Image1,
//     type: "",
//     price: "$2800",
//     location: "Boston",
//   },
//   {
//     id: 5,
//     title: "Property 5",
//     image: Image1,
//     type: "",
//     price: "$2700",
//     location: "New York",
//   },
//   {
//     id: 6,
//     title: "Property 6",
//     image: Image1,
//     type: "",
//     price: "$3100",
//     location: "Chicago",
//   },
//   {
//     id: 7,
//     title: "Property 7",
//     image: Image1,
//     type: "",
//     price: "$3100",
//     location: "San Diego",
//   },
// ];

const PropertyList = () => {
  const [properties, setProperties] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState("All");

  useEffect(() => {
    if (!properties.length) {
      const fetchProperties = async () => {
        try {
          const data = await getDevProperties();
          setProperties(data);
        } catch (err) {
          const errorMessage = handleApiError(err);
          setError(errorMessage);
        } finally {
          setIsLoading(false);
        }
      };
      fetchProperties();
    }
  }, [properties]);

  if (isLoading) {
    return (
      <LayoutSecondary>
        <main className="propertylist">
          <div className="loader d-flex justify-content-center align-items-center vh-100">
            <div className="text-center mb-0">
              <h1>{Strings.loading}</h1>
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </main>
      </LayoutSecondary>
    );
  }

  if (error) {
    return (
      <LayoutSecondary>
        <main className="propertylist">
          <h1>{Strings.errorOccurred}</h1>
          <p>{error}</p>
        </main>
      </LayoutSecondary>
    );
  }

  const propertiesPerPage = 6;
  const filteredProperties =
    selectedFilter === "All"
      ? properties
      : properties.filter(
          (property) => property.project_type_name === selectedFilter
        );

  const totalPages = Math.ceil(filteredProperties.length / propertiesPerPage);
  const displayedProperties = filteredProperties.slice(
    (currentPage - 1) * propertiesPerPage,
    currentPage * propertiesPerPage
  );

  if (!filteredProperties.length) {
    return (
      <LayoutSecondary>
        <main className="propertylist">
          <h1>{Strings.noPropertiesMatchFilter}</h1>
          <button onClick={() => setSelectedFilter("All")}>
            {Strings.resetFilter}
          </button>
        </main>
      </LayoutSecondary>
    );
  }

  if (!displayedProperties.length) {
    return (
      <LayoutSecondary>
        <main className="propertylist">
          <h1>{Strings.noPropertiesAvailable}</h1>
        </main>
      </LayoutSecondary>
    );
  }

  if (!properties.length) {
    return (
      <LayoutSecondary>
        <main className="propertylist">
          <h1>{Strings.noPropertiesAvailable}</h1>
        </main>
      </LayoutSecondary>
    );
  }

  return (
    <LayoutSecondary>
      <main className="propertylist">
        {/* Banner section */}
        <section
          className="propertylistbanner"
          aria-labelledby="propertiesHeader"
        >
          {Strings.propertiesHead}
          <div className="ms-auto d-none d-md-block">
            <img
              src={aboutUsBannerRight}
              alt="About us banner"
              aria-hidden="true"
            />
          </div>
        </section>

        {/* Property insights section */}
        <section
          className="propertylisthead"
          aria-labelledby="propertyInsightsHeader"
        >
          {Strings.propertyInsights}
        </section>

        {/* Property Filters */}
        {properties.length > propertiesPerPage && (
          <nav aria-label="Property Filters">
            <div className="propertylistfilter">
              <button
                onClick={() => setSelectedFilter("All")}
                aria-pressed={selectedFilter === "All"}
              >
                {Strings.all}
              </button>
              {["Commercial", "Retail", "Office", "Residential"].map(
                (category) =>
                  properties.some(
                    (prop) => prop.project_type_name === category
                  ) && (
                    <button
                      key={category}
                      onClick={() => setSelectedFilter(category)}
                      aria-pressed={selectedFilter === category}
                    >
                      {Strings[category.toLowerCase()] || category}
                    </button>
                  )
              )}
            </div>
          </nav>
        )}

        {/* Back to Development Link */}
        <section className="propertylistbacktodevelopment">
          <Link to="/development">{Strings.backToDevelopment}</Link>
        </section>

        {/* Property Cards */}
        <section
          className="propertylistgrid row"
          aria-labelledby="propertylist"
        >
          {displayedProperties.map((property) => {
            // Parse images from the property object
            let parsedImages = [];
            try {
              parsedImages = property.project_images
                ? JSON.parse(property.project_images)
                : [];
            } catch (error) {
              console.error("Invalid JSON in project_images:", error);
            }

            // Use the first image for display
            const heroImage =
              parsedImages.length > 0 && parsedImages[0]?.filename
                ? `${apiUrls.imagesURL}${parsedImages[0].filename}`
                : defaultImage;

            return (
              <div className="propertylistgridcard" key={property.id}>
                <div className="col-12 col-md-6">
                <div className="propertylistgridcardimage">
                  {/* Hero Image */}
                  <img
                    src={heroImage}
                    alt={property.propertyName}
                    loading="lazy"
                    onError={(e) => {
                      e.target.src = defaultImage;
                      console.error("Error loading image:", heroImage);
                    }}
                  />
                </div>

                {/* Property Details */}

                {/* Render property details only if a valid hero image is available */}
                {heroImage !== defaultImage ? (
                  <div className="propertylistgridcardoverlay">
                    <div className="propertylistgridcardoverlayname">
                      {property.propertyName}
                    </div>
                    <Link
                      to={`/development/property-list/property-details/${property.id}`}
                      className="propertylistgridcardoverlayviewmorebtn"
                    >
                      {Strings.viewMore}
                    </Link>
                  </div>
                ) : null}
                </div>
              </div>
            );
          })}
        </section>

        {/* Pagination */}
        {filteredProperties.length > propertiesPerPage && (
          <nav
            className="propertylistpagination"
            aria-label="Pagination Navigation"
          >
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              aria-disabled={currentPage === 1}
              aria-label="Previous Page"
            >
              {Strings.previous}
            </button>
            <span>
              {Strings.page} {currentPage} {Strings.of} {totalPages}
            </span>
            <button
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={currentPage === totalPages}
              aria-disabled={currentPage === totalPages}
              aria-label="Next Page"
            >
              {Strings.next}
            </button>
          </nav>
        )}

        {/* Compare Properties */}
        {/* <div className="compare-properties">
          <div className="careerpagetalent">
            <div className="careerpagetalenthead">
              COMPARE PROPERTIES IN LOREM
            </div>

            <div className="compare-cards-container">
              
              <div className="compare-property-card">
                <button className="add-button">+ADD</button>
              </div>
              
              <div className="compare-property-card">
                <button className="add-button">+ADD</button>
              </div>
              
              <div className="compare-property-card">
                <button className="add-button">+ADD</button>
              </div>
            </div>

            <div className="text-center">
              <button className="compare-button">Compare Properties</button>
            </div>
          </div>
        </div> */}
      </main>
    </LayoutSecondary>
  );
};

export default PropertyList;
