import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "components/Builders/Builders.scss";
import LayoutSecondary from "components/Layouts/LayoutSecondary/LayoutSecondary";
import builderBannerRightImage from "assets/images/Builders/builderbannerright.png";
import builderProcessImage from "assets/images/Builders/process.png";
import mottoIcon1 from "assets/images/Builders/mottoicon1.svg";
import mottoIcon2 from "assets/images/Builders/mottoicon2.svg";
import mottoIcon3 from "assets/images/Builders/mottoicon3.svg";
import mottoIcon4 from "assets/images/Builders/mottoicon4.svg";
import builderRightImage from "assets/images/Builders/builderright1.png";
import currentInvestmentImage1 from "assets/images/Development/currentinvestment1.png";
// import premiumAmmenitiesImage from "assets/images/Builders/premiumammenitiesimage.png";
import Strings from "components/Constants/Strings";
import { getBuildProperties } from "api/propertiesApi";
import apiUrls from "components/Constants/ApiUrls";
import CompletedInvestorProjects from "components/Builders/CompletedInvestorProjects";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "store/authSlice";
import SigninRegister from "components/SigninRegister/SigninRegister";

// const projects = [
//   {
//     id: 1,
//     name: "Riverfront Development Project",
//     image: currentInvestmentImage1,
//     status: "Available",
//   },
//   {
//     id: 2,
//     name: "Beachside Luxury Villa",
//     image: currentInvestmentImage2,
//     status: "Available",
//   },
//   {
//     id: 3,
//     name: "Mountain Retreat",
//     image: currentInvestmentImage2,
//     status: "Coming Soon",
//   },
//   {
//     id: 4,
//     name: "Urban Skyline",
//     image: currentInvestmentImage1,
//     status: "Coming Soon",
//   },
// ];

const Builders = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [properties, setProperties] = useState([]);
  const [error, setError] = useState(null); // Enable error state

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const data = await getBuildProperties();
        setProperties(data);
      } catch (err) {
        setError(err.message || Strings.defaultErrorMessage); // Handle error
      }
    };

    fetchProperties();
  }, []);

  if (error) {
    return (
      <LayoutSecondary>
        <main className="builder">
          <section className="builderbanner">
            <h1>{Strings.errorOccurred}</h1>
            <h2>{error}</h2>
          </section>
        </main>
      </LayoutSecondary>
    );
  }

  return (
    <LayoutSecondary>
      <main className="builder" aria-labelledby="builder-page">
        <section
          className="builderbanner"
          aria-labelledby="builder-banner-main"
        >
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-7">
              <div className="builderbannerhead">
                {Strings.welcomeText}
                <br />
                <span>{Strings.builders}</span> 
              </div>
              <div className="builderbannerdesc">
                {Strings.buildersPageBannerFirstDesc}
              </div>
              {/* <Link to="/contact-us">
                <button type="submit" className="mt-3 builderbannercontactbtn">
                  {Strings.contactUs}
                </button>
              </Link> */}
            </div>
            <div className="col-md-5 d-none d-md-block text-center">
              <div className="builderbannerimg">
                <img
                  src={builderBannerRightImage}
                  alt="Builders page banner right"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="builderprocess" aria-labelledby="builder-process">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-6 d-none d-md-block  text-center">
              <div className="builderprocessimg">
                <img src={builderProcessImage} alt="Builder process" />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="builderprocesshead">
                {Strings.ourProcessOne} <span>{Strings.ourProcessTwo}</span>
              </div>
              <div className="builderprocessdata">
                <ul>
                  <li>
                    <b className="builderprocessdatapoint">
                      {Strings.processPointOne}
                    </b>
                  </li>
                  <div className="builderprocessdatadesc">
                    {Strings.processPointOneDesc}
                  </div>
                  <li>
                    <b className="builderprocessdatapoint">
                      {Strings.processPointTwo}
                    </b>
                  </li>
                  <div className="builderprocessdatadesc">
                    {Strings.processPointOneDesc}
                  </div>
                  <li>
                    <b className="builderprocessdatapoint">
                      {Strings.processPointThree}
                    </b>
                  </li>
                  <div className="builderprocessdatadesc">
                    {Strings.processPointOneDesc}
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="buildermotto" aria-labelledby="builder-motto">
          <div className="buildermottohead">{Strings.buildermottohead}</div>
          <div className="row">
            <div className="col-12 col-md-3 mb-2">
              <div className="buildermottocard ">
                <div className="buildermottocardicon">
                  <img src={mottoIcon1} alt="Builder mottoIcon1" />
                </div>
                <div className="buildermottocardhead">{Strings.mottoOne}</div>
                <div className="buildermottocarddesc">
                  {Strings.mottoOneDesc}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-2">
              <div className="buildermottocard">
                <div className="buildermottocardicon">
                  <img src={mottoIcon2} alt="Builder mottoIcon2" />
                </div>
                <div className="buildermottocardhead">{Strings.mottoTwo}</div>
                <div className="buildermottocarddesc">
                  {Strings.mottoTwoDesc}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-2">
              <div className="buildermottocard">
                <div className="buildermottocardicon">
                  <img src={mottoIcon3} alt="Builder mottoIcon3" />
                </div>
                <div className="buildermottocardhead">{Strings.mottoThree}</div>
                <div className="buildermottocarddesc">
                  {Strings.mottoThreeDesc}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 mb-2">
              <div className="buildermottocard">
                <div className="buildermottocardicon">
                  <img src={mottoIcon4} alt="Builder mottoIcon4" />
                </div>
                <div className="buildermottocardhead">{Strings.mottoFour}</div>
                <div className="buildermottocarddesc">
                  {Strings.mottoFourDesc}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="builderluxcon"
          aria-labelledby="builder-luxuryconstruction"
        >
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-6">
              <div className="builderluxconhead">
                {Strings.luxuryConstructionOne}{" "}
                <span>{Strings.luxuryConstructionTwo}</span>
              </div>
              <div className="builderluxcondesc">
                {Strings.luxuryConstructionDesc}
              </div>
            </div>
            <div className="col-12 col-md-6 text-center">
              <div className="builderluxconimg">
                <img src={builderRightImage} alt="builder right" />
              </div>
            </div>
          </div>
        </section>

        {/*Builder Buyer Properties Section*/}
        <section
          className="builderbuyeropp"
          aria-labelledby="builder-buyeropportunities"
        >
          <h2 className="builderbuyeropphead">
            {Strings.currentBuyerOpportunitiesOne}{" "}
            <span>{Strings.currentBuyerOpportunitiesTwo}</span>
          </h2>
          <div className="builderbuyeroppgrid row">
            {properties.map((property) => {
              // Parse images from the property object
              let parsedImages = [];
              try {
                parsedImages = property.gallery_filenames
                  ? property.gallery_filenames
                      .split(",")
                      .map((filename) => ({ filename }))
                  : [];
              } catch (error) {
                console.error("Error parsing gallery_filenames:", error);
              }

              // Use the first image for display
              const heroImage =
                parsedImages.length > 0 && parsedImages[0]?.filename
                  ? `${apiUrls.imagesURL}${parsedImages[0].filename}`
                  : currentInvestmentImage1;

              return (
                <div
                  key={property.id}
                  className="builderbuyeroppgridcard col-12 col-md-6"
                  aria-labelledby={`property-${property.id}`}
                >
                  <div className="builderbuyeroppgridcardimage">
                    {/* Hero Image */}
                    <img
                      src={heroImage}
                      alt={property.propertyName}
                      loading="lazy"
                      onError={(e) => {
                        e.target.src = currentInvestmentImage1;
                        console.error("Error loading image:", heroImage);
                      }}
                    />
                  </div>
                  {/* Property Details */}
                  <div className="builderbuyeroppgridcardoverlay">
                    <span className="builderbuyeroppgridcardoverlayname">
                      {property.propertyName}
                    </span>
                    {isLoggedIn ? (
                      // If logged in, show the "View More" button linking to property details
                      <Link
                        to={`/builders/property-list/property-details/${property.id}`}
                        className="developmentpagecurrentinvestmentgridcardoverlaybutton"
                      >
                        {Strings.viewMore}
                      </Link>
                    ) : (
                      // If not logged in, show the "Enquiry" button linking to /contact-us
                      <Link
                        to="/contact-us"
                        className="developmentpagecurrentinvestmentgridcardoverlaybutton"
                      >
                        {Strings.enquiry}
                      </Link>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="builderbuyeroppviewall">
            {isLoggedIn ? (
              <Link
                className="developmentpagecurrentinvestmentviewalllink"
                to="/builders/property-list"
              >
                {Strings.viewAllProjects}
                <i className="fa-solid fa-chevron-right" aria-hidden="true"></i>
              </Link>
            ) : (
              <Link
                className="developmentpagecurrentinvestmentviewalllink"
                data-bs-toggle="modal"
                data-bs-target="#signinModal"
                aria-haspopup="dialog"
                aria-controls="signinModal"
              >
                {Strings.viewAllProjects}
                <i className="fa-solid fa-chevron-right" aria-hidden="true"></i>
              </Link>
            )}
            <SigninRegister />
          </div>
        </section>
        <CompletedInvestorProjects properties={properties} />
        {/* <section
          className="builderpremiumammenities"
          aria-labelledby="builder-premiumamenities"
        >
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-6 builderpremiumammenitiesdata">
              <div className="builderpremiumammenitiesdatahead">
                {Strings.premiumAmmenitiesOne}{" "}
                <span>{Strings.premiumAmmenitiesTwo}</span>
              </div>
              <div className="builderpremiumammenitiesdatadesc">
                {Strings.premiumAmmenitiesDescOne}
                <br />
                <p className="pt-1">{Strings.premiumAmmenitiesDescTwo}</p>
              </div>
            </div>
            <div className="col-12 col-md-6 text-center">
              <div className="builderpremiumammenitiesimg">
                <img
                  src={premiumAmmenitiesImage}
                  alt="Builder premium ammenities"
                />
              </div>
            </div>
          </div>
        </section> */}
        <section
          className="builderstatusbanner"
          aria-labelledby="builder-status-banner"
        >
          {Strings.buildFuture}
        </section>
      </main>
    </LayoutSecondary>
  );
};

export default Builders;
