const STAGE_API_BASE_URL = "https://stage.luckyduckgroup.com/Admin";

const apiUrls = {
  loginApi: `${STAGE_API_BASE_URL}/api/login`,
  devPropertiesList: `${STAGE_API_BASE_URL}/api/devProperties`,
  devCompletedProperties: `${STAGE_API_BASE_URL}/api/devCompProperties`,
  buildPropertiesList: `${STAGE_API_BASE_URL}/api/buildProperties`,
  buyerPropertiesList: `${STAGE_API_BASE_URL}/api/buyProperties`,
  detailedProperty: `${STAGE_API_BASE_URL}/api/detailedProperty`,
  contactFormSubmit: `${STAGE_API_BASE_URL}/api/register`,
  imagesURL: `${STAGE_API_BASE_URL}/storage/app/public/`,
};

export default apiUrls;
