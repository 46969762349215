import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//import Image1 from "assets/images/Development/beforeimage.png";
//import Image2 from "assets/images/Development/afterimage.png";
import Strings from "components/Constants/Strings";
import apiUrls from "components/Constants/ApiUrls";

// const carouselItems = [
//   {
//     beforeImage: Image1,
//     afterImage: Image2,
//   },
//   {
//     beforeImage: Image2,
//     afterImage: Image1,
//   },
// ];

const CompletedInvestorProjects = ({ properties }) => {
  //console.log("properties", properties);
  const carouselSettings = {
    arrows: true,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  /**
   * Parses a comma-separated string of image filenames into an array of URLs.
   * @param {string} imageString - Comma-separated filenames of images.
   * @returns {Array<string>} - Array of full image URLs or an empty array if invalid.
   * */
  const parseImageArray = (imageString) => {
    return imageString
      ? imageString.split(",").map((img) => `${apiUrls.imagesURL}${img.trim()}`)
      : [];
  };

  return (
    <section
      className="buildercompletedinvestorprojectscarousel"
      aria-labelledby="completedInvestorProjectsHeader"
    >
      <div
        id="completedInvestorProjectsHeader"
        className="buildercompletedinvestorprojectscarouselhead"
      >
        {Strings.completedInvestorProjects}
      </div>
      <Slider {...{ ...carouselSettings, infinite: properties.length > 1 }}>
        {properties.map((property, propertyIndex) => {
          // Parse "before" and "after" image URLs for each property
          const beforeImages = parseImageArray(property.gallery_beforeImgs);
          const afterImages = parseImageArray(property.gallery_afterImgs);

          // Skip rendering if no images are available
          if (beforeImages.length === 0 && afterImages.length === 0) {
            return null;
          }

          // Determine the total number of slides based on the larger image array
          const totalSlides = Math.max(beforeImages.length, afterImages.length);

          // Create slides dynamically for each property
          return Array.from({ length: totalSlides }).map((_, index) => (
            <div key={`${propertyIndex}-slide-${index}`}>
              <div
                className="buildercompletedinvestorprojectscarouselslide"
                aria-labelledby={`carouselSlideTitle-${propertyIndex}-${index}`}
              >
                <div>
                  {/* Before image section */}
                  <h3
                    id={`carouselSlideTitle-${propertyIndex}-${index}-before`}
                    className="buildercompletedinvestorprojectscarouselslideimage"
                  >
                    {Strings.before}
                  </h3>
                  {beforeImages[index] && (
                    <img
                      src={beforeImages[index]}
                      loading="lazy"
                      alt={`Before img for property ${property.propertyName}`}
                      aria-labelledby={`carouselSlideTitle-${propertyIndex}-${index}-before`}
                    />
                  )}
                </div>
                {/* After image section */}
                <div>
                  <h3
                    id={`carouselSlideTitle-${propertyIndex}-${index}-after`}
                    className="buildercompletedinvestorprojectscarouselslideimage"
                  >
                    {Strings.after}
                  </h3>
                  {afterImages[index] && (
                    <img
                      src={afterImages[index]}
                      loading="lazy"
                      alt={`After img for property ${property.propertyName}`}
                      aria-labelledby={`carouselSlideTitle-${propertyIndex}-${index}-after`}
                    />
                  )}
                </div>
              </div>
            </div>
          ));
        })}
      </Slider>
    </section>
  );
};

export default CompletedInvestorProjects;
