const Strings = {
  // Home.jsx
  welcomeText: "WELCOME TO",
  companyName: "LUCKY DUCK GROUP",
  homePageBannerFirstDescOne:
    "We are more than a business. We are a family of companies that work together help you reach your goals as a real estate investor or owner.",
  homePageBannerFirstDescTwo:
    "We are family owned and operated specializing in finding, developing and building unique property experiences. Developed by our family for yours.",
  buildFuture: "Let's Build Our Future Together",
  viewMore: "View More",
  comingSoon: "Coming Soon",
  homePageIntroHeadOne: "OUR",
  homePageIntroHeadTwo: "EXPERTISE",
  homePageIntroCardHeadOne: "INVESTMENT OPPORTUNITIES",
  homePageIntroCardDescOne:
    "LDG Development LLC offers attractive equity investment opportunities in luxury real estate projects. Investors can participate in high-return ventures with a focus on premium properties.",
  homePageIntroCardHeadTwo: "STRATEGIC LAND ACQUISITION",
  homePageIntroCardDescTwo:
    "The company identifies and acquires prime waterfront properties with strong appreciation potential, ensuring high-value investments.",
  homePageIntroCardHeadThree: "CUSTOM CONSTRUCTION",
  homePageIntroCardDescThree:
    "Every property features a design born of thoughtful choices and amenities that make the most of the location and the Southwest Florida lifestyle. Homes are built with the finest craftsmanship and materials specifically curated for each project.",
  homePageTourHeadOne: "IN YOUR",
  homePageTourHeadTwo: "CORNER",
  loremText:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",

  //About.jsx
  aboutUs: "About Us",
  ourStoryOne: "Our",
  ourStoryTwo: "Story",
  ourStoryDesc:
    "Lucky Duck Group is a consortium of companies dedicated to the development, construction, and property management of luxury residences in Southwest Florida. The group operates in prime locations such as Naples, Fort Myers, Fort Myers Beach, Cape Coral, and Bonita Springs, offering a comprehensive range of services to meet the needs of high-end real estate investors and home owners.",
  ourMission: "Mission Statement",
  ourMissionDesc:
    "We are more than a business; we are a family of companies that work together help you reach your goals as a real estate investor or owner. We are family owned and operated specializing in finding, developing, and building unique property experiences.",
  buildFutureOne: "Let's Build Our",
  buildFutureTwo: "Future Together",
  meetTheTeam: "Meet the Team",

  aboutPageBannerFirstDescOne:
    "We are more than a business; we are a family of companies that work together help you reach your goals as a real estate investor or owner.",
  aboutPageBannerFirstDescTwo:
    "We are family owned and operated specializing in finding, developing and building unique property experiences.Developed by our family for yours.",

  //Contact.jsx
  contactUs: "Contact Us",
  contactPageFormHeadOne: "LET'S GET",
  contactPageFormHeadTwo: "IN TOUCH",
  firstName: "First Name",
  lastName: "Last Name",
  emailAddr: "Email Address",
  phone: "Phone Number",
  type: "Select Type",
  subType: "Select Sub Type",
  message: "Message",
  getMoreInfo: "Get more information",
  submitting: "Submitting...",
  failedToSubmit: "Failed to submit contact form.",
  failedToSave: "Failed to save Microsoft Form response.",

  typeOptions: [
    { value: "1", label: "Investment Opportunities" },
    { value: "2", label: "Purchasing a Property" },
    { value: "3", label: "Construction Project" },
    { value: "4", label: "Property Management" },
    { value: "5", label: "Enquiry" },
  ],

  subTypeOptions: [
    { value: "1", label: "Individual Investor" },
    { value: "2", label: "Group Investment" },
  ],

  //Careers.jsx
  careers: "Careers",
  joinOurTalentCommunity: "JOIN OUR TALENT COMMUNITY",
  joinOurTalentCommunityOne: "JOIN OUR TALENT",
  joinOurTalentCommunityTwo: "COMMUNITY",
  discoverTheOpportunityOne: "DISCOVER THE",
  discoverTheOpportunityTwo: "OPPORTUNITY",
  discoverTheOpportunityDesc:
    "Lucky Duck Group is a family of companies dedicated to the development, construction, and property management of exceptional properties in Southwest Florida. LDG operates in prime locations and offer a comprehensive range of services to meet the needs of investors, homeowners and our team members.  If you are passionate about developing opportunities in Southwest Florida, consider developing an opportunity for yourself by joining our family.",
  apply: "Apply",
  uploadResume: "Upload Resume",
  uploadButton: "Upload",
  jobTitle: "Job Title",
  location: "Location",

  //Development.jsx
  development: "LDG DEVELOPMENT",
  developmentPageBannerFirstDesc:
    "We Are A Leading Real Estate Investment Company Specializing In The Development Of Unique Properties In Southwest Florida. We Search To Acquire Properties That We Transform Into High Value Opportunities That Will Maximize Returns For Our Investors. Our Focus Is On Developing Unique Waterfront Properties In Prime Locations",
  investmentStrategyOne: "INVESTMENT",
  investmentStrategyTwo: "STRATEGY",
  marketFocus: "MARKET FOCUS",
  marketFocusDesc:
    "Lucky Duck Development targets buyers and investors looking for exceptional waterfront properties. The company leverages the appeal of Southwest Florida’s coastal lifestyle to attract clients seeking both primary residences, vacation homes and investment properties for sale or their portfolios.",
  equityFinancing: "EQUITY FINANCING",
  equityFinancingDesc:
    "The Company Raises Outside Capital Through Limited Equity Investment Offerings.  Building our future together with Accredited Individual and Entity Investors via membership interests in select development opportunities.",
  exclusiveLocations: "EXCLUSIVE LOCATIONS",
  exclusiveLocationsDesc:
    "The company focuses on acquiring and developing properties in the most sought-after waterfront locations, offering stunning views and direct access to water activities.",
  riskMitigation: "RISK MITIGATION",
  riskMitigationDesc:
    "By Focusing High-Demand Markets & Maintaining A Diverse Portfolio Of Properties LDG Development Mitigates Risk Associated With Speculative Building.",
  costManagement: "COST MANAGEMENT",
  costManagementDesc:
    "Efficient Cost Management And A Strong Business Plan Are Crucial To Maximising Returns On Spec Home Projects",
  highRoi: "HIGH ROI",
  highRoiDesc:
    "The Company Airs To Delver High Returns On Investment By Developing Properties in Prime Location & Ensuring Top-Quality Contruction And Amenities",
  yourDream: "YOUR DREAM OUR ASSIGNMENT",
  // marketFocus: "MARKET FOCUS",
  // marketFocusDesc:
  //   "Lucky Duck Builders targets affluent buyers and investors looking for luxury waterfront properties. The company leverages the appeal of Southwest Florida’s coastal lifestyle to attract clients seeking both primary residences and vacation homes.",
  // exclusiveLocations: "EXCLUSIVE LOCATIONS",
  // exclusiveLocationsDesc:
  //   "The company focuses on acquiring and developing properties in the most sought-after waterfront locations, offering stunning views and direct access to water activities.",
  // personalizedService: "PERSONALISED SERVICE",
  // personalizedServiceDesc:
  //   "The company provides a personalized experience for investors and buyers, offering bespoke design options and dedicated project management to ensure every detail meets the highest standards.",
  currentInvestmentOne: "CURRENT INVESTMENT",
  currentInvestmentTwo: "OPPORTUNITIES",
  enquiry: "Enquiry",
  viewAllProjects: "View All Projects",
  investorRelations: "INVESTOR RELATIONS",
  investorRelationsDesc:
    "LDG Development maintains transparent and professional communication with investors, providing regular updates on project progress and financial performance. We are a click or call away whenever you want to connect with us.",
  price: "Price:",
  area: "Area:",
  size: "Size:",
  bedrooms: "Bedrooms:",
  rooms: "Rooms:",
  structure: "Structure Type:",
  before: "Before",
  after: "After",
  completedInvestorProjects: "DELIVERED PROJECTS",
  completedProjectsOne: "COMPLETED",
  completedProjectsTwo: "PROJECTS",
  propertiesHead: "PROPERTIES",
  propertyInsights: "PROPERTY INSIGHTS",
  all: "All",
  commercial: "Commercial",
  retail: "Retail",
  office: "Office",
  residential: "Residential",
  backToDevelopment: "Back To LDG Development",
  previous: "Previous",
  next: "Next",
  page: "Page",
  of: "of",
  noPropertiesAvailable: "No Properties Found",

  //PropertyDetails.jsx
  propertyNotFoundErrorMessage: "Property not found",
  loremShort: "Lorem Ipsum",
  projectSize: "Project Size",
  projectDate: "Project Date",
  downloadBrochure: "Brochure - Currently in Progress",
  developmentPage: "LDG Development",
  propertyList: "List",
  propertyDetails: "Property Details",
  aboutProject: "ABOUT PROJECT",
  backToList: "Back To List",
  loremOne: "LOREM IPSUM DOLOR SIT AMET, CONSECTETUR ADIPISCING ELIT, SED",
  loremTwo: "DO EIUSMOD TEMPOR INCIDIDUNT UT LABORE ET DOLORE MAGNA",
  loremThree: "ALIQUA. UT ENIM AD MINIM VENIAM, QUIS NOSTRUD EXERCITATION",
  loremFour: "ULLAMCO.",
  getMoreInfoAbout: "Get More Information About This Project",
  projectGallery: "PROJECT GALLERY",
  view: "View",
  projectCategory: "Category",
  projectType: "Type",
  locationAddress: "Address",
  propertyName: "Property Name",

  //Header.jsx
  headerHome: "Home",
  headerAboutUs: "About Us",
  headerContactUs: "Contact Us",
  headerCareers: "Careers",
  headerLogin: "Login",
  headerLogout: "Logout",
  headerDashboard: "Dashboard",

  //Footer.jsx
  footerCopyright: "© Copyright Lucky Duck Group All Rights Reserved",

  //Builders.jsx
  builders: "LUCKY DUCK BUILDERS",
  buildersPageBannerFirstDesc:
    "Specializing in construction of high-end properties in prime waterfront locations across Southwest Florida.  The company aims to create bespoke properties that cater to discerning buyers looking for personal residences, vacation homes or investment properties for their portfolios.",
  ourProcessOne: "OUR",
  ourProcessTwo: "PROCESS",
  processPointOne: "DEFINE",
  processPointOneDesc:
    "sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  processPointTwo: "DESIGN",
  processPointThree: "DELIVER",
  buildermottohead: "BUILDING STRATEGY",
  mottoOne: "CONSTRUCTION",
  mottoOneDesc:
    "We build with the location in mind.  Constructed to withstand the weather in SW Florida featuring a raised design, hurricane resistant glass, generator/battery/water and power storage options. Our projects are focused on meeting a gap in the housing market of  brought on by hurricane damage and population growth.",
  mottoTwo: "PREMIUM AMENITIES",
  mottoTwoDesc:
    "Our properties are built with the finest materials and craftsmanship. Features include custom cabinetry, high-end appliances, solid surface countertops, premium flooring, elevators, and smart home technology.",
  mottoThree: "PERSONALISED SERVICE",
  mottoThreeDesc:
    "The company provides a personalized experience for investors and buyers, offering bespoke design options and dedicated project management to ensure every detail meets the highest standards and the best product on the market.",
  mottoFour: "COST MANAGEMENT",
  mottoFourDesc:
    "Efficient Cost Management limited vendor transitions and A Strong Business Pian Are Crucial To Maximizing Returns On all our projects",
  luxuryConstructionOne: "LUXURY",
  luxuryConstructionTwo: "CONSTRUCTION",
  luxuryConstructionDesc:
    "homes are built with the finest materials and craftsmanship.Features include custom cabinetry, high-end appliances, marble countertops, hardwood floors, and smart home technology.",
  currentBuyerOpportunitiesOne: "CURRENT BUYER",
  currentBuyerOpportunitiesTwo: "OPPORTUNITIES",
  premiumAmmenitiesOne: "PREMIUM",
  premiumAmmenitiesTwo: "AMMENITIES",
  premiumAmmenitiesDescOne:
    "We are more than a business; we are a family of companies that work together help you reach your goals as a real estate investor or owner.",
  premiumAmmenitiesDescTwo:
    "We are family owned and operated specializing in finding, developing and building unique property experiences.Developed by our family for yours. Let’s travel this journey together",
  backToBuilders: "Back To LDG Builders",
  buildersPage: "LDG Builders",

  //propertymanagement.jsx
  propertyManagement: "LDG PROPERTY MANAGEMENT",
  propertyManagementDesc:
    "Is Dedicated To Providing Comprehensive Management Services For Multifamily Properties, Ensuring That Both Property Owners And Tenants Have A Seamless And Satisfying Experience. The Company Operates In Key Locations Across Southwest Florida, Including Fort Myers, Cape Coral, Naples, And Bonita Springs.",
  keyFeaturesOne: "key features and",
  keyFeaturesTwo: "services",
  featureServiceOne: "tenant screening and placement",
  featureServiceOneDesc:
    "LDG PM LLC conducts thorough tenant screening to ensure reliable and responsible tenants. This includes background checks, credit checks, and rental history verification.",
  featureServiceTwo: "rent collection and financial reporting",
  featureServiceTwoDesc:
    "The company handles all aspects of rent collection, ensuring timely payments and providing detailed financial reports to property owners. These reports include income statements, expense reports, and maintenance cost summaries.",
  featureServiceThree: "Online Portals",
  featureServiceThreeDesc:
    "Both property owners and tenants have access to online portals for easy management of accounts, maintenance requests, and financial tracking.",
  featureServiceFour: "Smart Home Technology",
  featureServiceFourDesc:
    "The company integrates smart home technology in managed properties to enhance security and convenience for tenants.",
  featureServiceFive: "Property Maintenance and Repairs",
  featureServiceFiveDesc:
    "LDG PM LLC coordinates all maintenance and repair requests, working with trusted vendors to ensure high-quality work and prompt service. Regular property inspections are conducted to maintain the condition of the buildings.",
  featureServiceSix: "Marketing and Leasing",
  featureServiceSixDesc:
    "The company aggressively markets available units through various online platforms and local advertising to minimize vacancy rates. Professional leasing agents handle showings and lease signings.",
  featureServiceSeven: "Legal Compliance and Evictions",
  featureServiceSevenDesc:
    "LDG PM LLC ensures that all properties comply with local, state, and federal regulations. They also manage the eviction process if necessary, handling all legal proceedings to protect the property owner’s interests",
  featureServiceEight: "Resident Services",
  featureServiceEightDesc:
    "The company offers a range of services to enhance tenant satisfaction, including online rent payment options, a responsive maintenance request system, and community-building activities.",
  Lorempara:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit",
  viewRentalProperty: "View Rental Property",
  propertyManagementmarketFocus: "MARKET FOCUS",
  propertyManagementmarketFocusDesc:
    "The company provides a personalized ex perience for investors and buyers, offering bespoke design options and dedicated project management to ensure every detail meets the highest standards.",
  propertyManagementcustomerExperience: "CUSTOMER EXPERIENCE",
  propertyManagementcustomerExperienceDesc:
    "The company provides a personalized ex perience for investors and buyers, offering bespoke design options and dedicated project management to ensure every detail meets the highest standards.",

  //siginRegister.jsx
  buyer: "Buyer",
  investor: "Investor",
  renter: "Renter",
  email: "Email",
  backToLogin: "Back To Login",
  password: "Password",
  forgotMyPassword: "Forgot my password",
  rememberMe: "Remember Me",
  login: "Login",
  forgotPasswordForm: "Forgot Password",
  signInRegister: "Register/Sign In",
  sendResetLink: "Send Reset Link",

  //error handling
  errorOccurred: "An error occurred while fetching properties.",
  defaultErrorMessage: "Something went wrong. Please try again later.",
  loading: "Loading...",
  networkError: "Network error. Please check your internet connection.",

  //contact api
  individualInvestment: "https://forms.office.com/r/qQLu5YXmjC",
  groupInvestment: "https://forms.office.com/r/v0AJh5iRPS",
};

export default Strings;
