import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrls from "components/Constants/ApiUrls";

// Login User Thunk
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (userData, thunkAPI) => {
    try {
      //console.log("Login Request: ", userData);

      const interestMap = {
        Investor: 1,
        Buyer: 2,
        Renter: 3,
      };

      const payload = {
        email: userData.email,
        password: userData.password,
        interest: interestMap[userData.userType],
      };

      //console.log("Payload", payload);
      const response = await axios.post(apiUrls.loginApi, payload);

      console.log("Login API Response:", response.data); // Log the response

      const { user, token, dashboard } = response.data;

      // Store token based on rememberMe
      if (userData.rememberMe) {
        localStorage.setItem("token", token);
      } else {
        sessionStorage.setItem("token", token);
      }

      return { user, token, dashboard };
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || "Login failed"
      );
    }
  }
);

// Forgot Password Thunk
export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (emailData, thunkAPI) => {
    try {
      console.log("Forgot Password Request: ", emailData);

      // const response = await axios.post();
      // Simulated successful response
      return { message: "Password reset link sent to your email." };
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "Failed to send reset link"
      );
    }
  }
);

// Auth Slice
const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token:
      localStorage.getItem("token") || sessionStorage.getItem("token") || null,
    dashboard: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.dashboard = null;
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.dashboard = action.payload.dashboard;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    //     // Forgot Password
    //     .addCase(forgotPassword.pending, (state) => {
    //       state.isLoading = true;
    //       state.error = null;
    //       state.message = null;
    //     })
    //     .addCase(forgotPassword.fulfilled, (state, action) => {
    //       state.isLoading = false;
    //       state.message = action.payload.message;
    //     })
    //     .addCase(forgotPassword.rejected, (state, action) => {
    //       state.isLoading = false;
    //       state.error = action.payload || "Failed to send reset link.";
    //     });
  },
});

export const selectIsLoggedIn = (state) => !!state.auth.token;
export const selectShowDashboard = (state) => state.auth.dashboard;
export const { logout } = authSlice.actions;
export default authSlice.reducer;
