import React, { useState, useEffect } from "react";
import "components/ScrollToTop/ScrollToTop.scss";

const ScrollToTop = () => {
  const [showButton, setShowButton] = useState(false);

  const checkScrollPosition = () => {
    if (window.scrollY > 150) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollPosition);
    return () => {
      window.removeEventListener("scroll", checkScrollPosition);
    };
  }, []);

  return (
    <>
      {showButton && (
        <button
          onClick={scrollToTop}
          className="btn scrollBtn position-fixed bottom-0 end-0 m-3"
        >
          <i className="fa-solid fa-circle-up fs-2"></i>
        </button>
      )}
    </>
  );
};

export default ScrollToTop;
