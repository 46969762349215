import React from "react";
import LayoutSecondary from "components/Layouts/LayoutSecondary/LayoutSecondary";
import { Link } from "react-router-dom";
import "components/PropertyManagement/PropertyManagement.scss";
import builderBannerRightImage from "assets/images/Builders/builderbannerright.png";
import tenantScreeningImage from "assets/images/PropertyManagement/tenantscreeningandplacementimg.png";
import rentCollectionImage from "assets/images/PropertyManagement/rentcollectionimg.png";
import onlinePortalsImage from "assets/images/PropertyManagement/onlineportalsimg.png";
import smarthomeTechImage from "assets/images/PropertyManagement/smarthometechimg.png";
import propertyMainteinanceImage from "assets/images/PropertyManagement/propertymainteinanceimg.png";
import marketingandLeasingImage from "assets/images/PropertyManagement/marketingandleasing.png";
import legalComplianceImage from "assets/images/PropertyManagement/legalcomplianceimg.png";
import residentServicesImage from "assets/images/PropertyManagement/residentservicesimg.png";
import Strings from "components/Constants/Strings";

const PropertyManagement = () => {
  return (
    <LayoutSecondary>
      <main className="propertymanagement">
        <section className="propertymanagementbanner" aria-labelledby ="propertymanagement-banner">
          <div className="row align-items-center justify-content-center">
            <div className="col-7">
              <div className="builderbannerhead">
                {Strings.welcomeText}
                <br />
                <span>{Strings.propertyManagement}</span> 
              </div>
              <div className="propertymanagementbannerdesc">
                {Strings.propertyManagementDesc}
                </div>
              <Link to="/contact-us">
                <button
                  type="submit"
                  className="mt-3 propertymanagementbannercontactbtn "
                >
                  {Strings.contactUs}
                </button>
              </Link>
            </div>
            <div className="col-5 text-center">
              <div className="propertymanagementbannerimg">
                <img src={builderBannerRightImage} alt="Property management banner right" />
              </div>
            </div>
          </div>
        </section>

        <section className="propertymanagementservices" aria-labelledby="propertymanagement-services">
          <div className="propertymanagementserviceshead">
           {Strings.keyFeaturesOne} <span>{Strings.keyFeaturesTwo} </span>
          </div>
          <div className="row g-2">
            <div className="col-12 col-md-3">
              <div className="propertymanagementservicescard">
                <div className="propertymanagementservicescardicon">
                  <img src={tenantScreeningImage} alt="Tenant screening" />
                </div>
                <div className="propertymanagementservicescardhead">
                  {Strings.featureServiceOne}
                </div>
                <div className="propertymanagementservicescarddesc">
                {Strings.featureServiceOneDesc}{" "}
                  </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="propertymanagementservicescard">
                <div className="propertymanagementservicescardicon">
                  <img src={rentCollectionImage} alt="Rent collection" />
                </div>
                <div className="propertymanagementservicescardhead">
                {Strings.featureServiceTwo}
                </div>
                <div className="propertymanagementservicescarddesc">
                {Strings.featureServiceTwoDesc}{" "}
                  </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="propertymanagementservicescard">
                <div className="propertymanagementservicescardicon">
                  <img src={onlinePortalsImage} alt="Online portals" />
                </div>
                <div className="propertymanagementservicescardhead">
                {Strings.featureServiceThree}
                </div>
                <div className="propertymanagementservicescarddesc">
                {Strings.featureServiceThreeDesc}{" "}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="propertymanagementservicescard">
                <div className="propertymanagementservicescardicon">
                  <img src={smarthomeTechImage} alt="Smart home tech" />
                </div>
                <div className="propertymanagementservicescardhead">
                {Strings.featureServiceFour}
                </div>
                <div className="propertymanagementservicescarddesc">
                {Strings.featureServiceFourDesc}{" "}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="propertymanagementservicescard">
                <div className="propertymanagementservicescardicon">
                  <img src={propertyMainteinanceImage} alt="Property Maintainance" />
                </div>
                <div className="propertymanagementservicescardhead">
                {Strings.featureServiceFive}
                </div>
                <div className="propertymanagementservicescarddesc">
                {Strings.featureServiceFiveDesc}{" "}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="propertymanagementservicescard">
                <div className="propertymanagementservicescardicon">
                  <img src={marketingandLeasingImage} alt="Marketing and Leasing" />
                </div>
                <div className="propertymanagementservicescardhead">
                {Strings.featureServiceSix}
                </div>
                <div className="propertymanagementservicescarddesc">
                {Strings.featureServiceSixDesc}{" "}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="propertymanagementservicescard">
                <div className="propertymanagementservicescardicon">
                  <img src={legalComplianceImage} alt="Legal Compliance" />
                </div>
                <div className="propertymanagementservicescardhead">
                {Strings.featureServiceSeven}
                </div>
                <div className="propertymanagementservicescarddesc">
                {Strings.featureServiceSevenDesc}{" "}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="propertymanagementservicescard">
                <div className="propertymanagementservicescardicon">
                  <img src={residentServicesImage} alt="Resident Services" />
                </div>
                <div className="propertymanagementservicescardhead">
                {Strings.featureServiceEight}
                </div>
                <div className="propertymanagementservicescarddesc">
                {Strings.featureServiceEightDesc}{" "}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="propertymanagementrentalproperty" aria-labelledby ="propertyrentalproperty">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-8">
              <div className="propertymanagementrentalpropertydesc">
                {Strings.Lorempara}
              </div>
            </div>
            <div className="col-12 col-md-4 d-flex justify-content-center align-items-center">
              <Link to="/property-management/rental-property">
                <button
                  type="button"
                  className="propertymanagementrentalpropertyviewbtn"
                >
                  {Strings.viewRentalProperty}
                </button>
              </Link>
            </div>
          </div>
        </section>

        <section className="propertymanagementdualbanner" aria-labelledby="propertymanagement-dualbanner">
          <div className="row">
            <div className="col-12 col-md-6 propertymanagementdualbannermarketfocus">
              <div className="propertymanagementdualbannermarketfocushead">
                {Strings.propertyManagementmarketFocus}
              </div>
              <div className="propertymanagementdualbannermarketfocusdesc">
              {Strings.propertyManagementmarketFocusDesc}
              </div>
            </div>
            <div className="col-12 col-md-6 propertymanagementdualbannercustomerexp">
              <div className="propertymanagementdualbannercustomerexphead">
                {Strings.propertyManagementcustomerExperience}
              </div>
              <div className="propertymanagementdualbannercustomerexpdesc">
                {Strings.propertyManagementcustomerExperienceDesc}
              </div>
            </div>
          </div>
        </section>

        <section className="propertymanagementstatusbanner" aria-labelledby="propertymanagement-statusbanner">
          {Strings.buildFuture}
        </section>
      </main>
    </LayoutSecondary>
  );
};

export default PropertyManagement;
