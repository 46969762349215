import React from "react";
import Header from "components/Headers/HeaderSecondary/HeaderSecondary";
import "components/Headers/HeaderSecondary/HeaderSecondary.scss";
import "components/Footer/Footer.scss";
import Footer from "components/Footer/Footer";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import ScrollToTopDefault from "components/Layouts/LayoutPrimary/ScrollToTopDefault"

const LayoutSecondary = (props) => {
  return (
    <div>
      <ScrollToTopDefault />
      <ScrollToTop />
      <Header />
      <main>{props.children}</main>
      <Footer />
    </div>
  );
};

export default LayoutSecondary;
