import { useEffect } from "react";
import { useLocation } from "react-router-dom"; // Import to track location changes

const ScrollToTopDefault = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top
  }, [location]); // Dependency on location ensures it runs when the URL changes

  return null;
};

export default ScrollToTopDefault;
