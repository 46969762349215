import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "components/Development/Development.scss";
import LayoutSecondary from "components/Layouts/LayoutSecondary/LayoutSecondary";
import firstBannerImage from "assets/images/Development/developmentbannerone.png";
import secondBannerImage from "assets/images/Development/financialstratergy.png";
import defaultImage from "assets/images/Commonfiles/coming-soon.png";
// import marketFocusImage from "assets/images/Development/marketfocus.png";
// import exclusivelocationsImage from "assets/images/Development/exclusivelocations.png";
// import personalizedserviceImage from "assets/images/Development/personalizedservice.png";
import CompletedProjects from "components/Development/CompletedProjects";
import Strings from "components/Constants/Strings";
import { getDevProperties } from "api/propertiesApi";
import apiUrls from "components/Constants/ApiUrls";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "store/authSlice";
import SigninRegister from "components/SigninRegister/SigninRegister";

const Development = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [properties, setProperties] = useState([]);
  const [error, setError] = useState(null); // Enable error state

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const data = await getDevProperties();
        setProperties(data);
      } catch (err) {
        setError(err.message || Strings.defaultErrorMessage); // Handle error
      }
    };

    fetchProperties();
  }, []);

  if (error) {
    return (
      <LayoutSecondary>
        <main className="developmentpage">
          <section className="developmentpagebanner">
            <h1>{Strings.errorOccurred}</h1>
            <h2>{error}</h2>
          </section>
        </main>
      </LayoutSecondary>
    );
  }

  return (
    <LayoutSecondary>
      <main className="developmentpage">
        {/* Banner section */}
        <section className="developmentpagebanner">
          <div className="row">
            <div className="col-12 col-md-7 ">
              <div
                id="developmentHeader"
                className="developmentpagebannerfirsthead"
              >
                {Strings.welcomeText} <br />
                <span>{Strings.development}</span>
              </div>
              <div className="developmentpagebannerfirstdesc">
                {Strings.developmentPageBannerFirstDesc}
              </div>
              {/* <Link to="/contact-us">
                <button
                  type="submit"
                  className="developmentpagebannerfirstcontactbtn mt-3"
                >
                  {Strings.contactUs}
                </button>
              </Link> */}
            </div>
            <div className="col-md-5 d-none d-md-block developmentpagebannerfirstimage">
              <img src={firstBannerImage} alt="Development page banner right" />
            </div>
          </div>
        </section>

        {/* Intro Section */}
        <section
          className="developmentpageinvestmentstrategy"
          aria-labelledby="investmentStrategyHeader"
        >
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-6">
              <div
                id="investmentStrategyHeader"
                className="developmentpageinvestmentstrategyhead"
              >
                {Strings.investmentStrategyOne}{" "}
                <span>{Strings.investmentStrategyTwo} </span>
              </div>
              <ul>
                <li>
                  <b className="developmentpageinvestmentstrategypoint">
                    {Strings.marketFocus}
                  </b>
                </li>
                <div className="developmentpageinvestmentstrategydesc">
                  {Strings.marketFocusDesc}
                </div>
                <li>
                  <b className="developmentpageinvestmentstrategypoint">
                    {Strings.equityFinancing}
                  </b>
                </li>
                <div className="developmentpageinvestmentstrategydesc">
                  {Strings.equityFinancingDesc}
                </div>
                <li>
                  <b className="developmentpageinvestmentstrategypoint">
                    {Strings.exclusiveLocations}
                  </b>
                </li>
                <div className="developmentpageinvestmentstrategydesc">
                  {Strings.exclusiveLocationsDesc}
                </div>
                <li>
                  <b className="developmentpageinvestmentstrategypoint">
                    {Strings.riskMitigation}
                  </b>
                </li>
                <div className="developmentpageinvestmentstrategydesc">
                  {Strings.riskMitigationDesc}
                </div>
                {/* <li>
                  <b className="developmentpageinvestmentstrategypoint">
                    {Strings.costManagement}
                  </b>
                </li>
                <div className="developmentpageinvestmentstrategydesc">
                  {Strings.costManagementDesc}
                </div>
                <li>
                  <b className="developmentpageinvestmentstrategypoint">
                    {Strings.highRoi}
                  </b>
                </li>
                <div className="developmentpageinvestmentstrategydesc">
                  {Strings.highRoiDesc}
                </div> */}
              </ul>
            </div>
            <div className="col-6 d-none d-md-block text-center">
              <div className="developmentpageinvestmentstrategybanner">
                <img
                  src={secondBannerImage}
                  alt="Development page investment strategy"
                />
              </div>
            </div>
          </div>
        </section>

        <CompletedProjects />

        {/* Dream assignment section
        <section className="developmentpageassignmentouter">
          <div className="developmentpageassignment">
            <div className="developmentpageassignmenthead">
              {Strings.yourDream}
            </div>
            <div className="row">
              <div className="col-4">
                <div className="developmentpageassignmentcard">
                  <div className="">
                    <img src={marketFocusImage} alt="Market focus" />
                  </div>
                  <div className="developmentpageassignmentcardhead">
                    {Strings.marketFocus}
                  </div>
                  <div className="developmentpageassignmentcarddata">
                    {Strings.marketFocusDesc}
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="developmentpageassignmentcard">
                  <div className="">
                    <img
                      src={exclusivelocationsImage}
                      alt="Exlusive locations"
                    />
                  </div>
                  <div className="developmentpageassignmentcardhead">
                    {Strings.exclusiveLocations}
                  </div>
                  <div className="developmentpageassignmentcarddata">
                    {Strings.exclusiveLocationsDesc}
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="developmentpageassignmentcard">
                  <div className="">
                    <img
                      src={personalizedserviceImage}
                      alt="Personalised Service"
                    />
                  </div>
                  <div className="developmentpageassignmentcardhead">
                    {Strings.personalizedService}
                  </div>
                  <div className="developmentpageassignmentcarddata">
                    {Strings.personalizedServiceDesc}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* Current investment section */}
        <section
          className="developmentpagecurrentinvestment"
          aria-labelledby="currentInvestmentHeader"
        >
          <div
            id="currentInvestmentHeader"
            className="developmentpagecurrentinvestmenthead"
          >
            {Strings.currentInvestmentOne}{" "}
            <span>{Strings.currentInvestmentTwo}</span>
          </div>
          <div className="developmentpagecurrentinvestmentgrid row">
            {properties.map((property) => {
              // Parse images from the property object
              let parsedImages = [];
              try {
                parsedImages = property.project_images
                  ? JSON.parse(property.project_images)
                  : [];
              } catch (error) {
                console.error("Invalid JSON in project_images:", error);
              }

              // Use the first image for display
              const heroImage =
                parsedImages.length > 0 && parsedImages[0]?.filename
                  ? `${apiUrls.imagesURL}${parsedImages[0].filename}`
                  : defaultImage;

              return (
                <div
                  key={property.id}
                  className="developmentpagecurrentinvestmentgridcard col-12 col-md-6"
                  aria-labelledby={`property-${property.id}`}
                >
                  <div className="developmentpagecurrentinvestmentgridcardimage">
                    {/* Hero Image */}
                    <img
                      src={heroImage}
                      alt={property.propertyName}
                      loading="lazy"
                      onError={(e) => {
                        e.target.src = defaultImage;
                        console.error("Error loading image:", heroImage);
                      }}
                    />
                  </div>
                  {/* Property Details */}

                  {/* Render property details only if a valid hero image is available */}
                  {heroImage !== defaultImage ? (
                    <div className="developmentpagecurrentinvestmentgridcardoverlay">
                      <span className="developmentpagecurrentinvestmentgridcardoverlayname">
                        {property.propertyName}
                      </span>
                      {isLoggedIn ? (
                        // If logged in, show the "View More" button linking to property details
                        <Link
                          to={`/development/property-list/property-details/${property.id}`}
                          className="developmentpagecurrentinvestmentgridcardoverlaybutton"
                        >
                          {Strings.viewMore}
                        </Link>
                      ) : (
                        // If not logged in, show the "Enquiry" button linking to /contact-us
                        <Link
                          to="/contact-us"
                          className="developmentpagecurrentinvestmentgridcardoverlaybutton"
                        >
                          {Strings.enquiry}
                        </Link>
                      )}
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
          <div className="developmentpagecurrentinvestmentviewall">
            {isLoggedIn ? (
              <Link
                className="developmentpagecurrentinvestmentviewalllink"
                to="/development/property-list"
              >
                {Strings.viewAllProjects}
                <i className="fa-solid fa-chevron-right" aria-hidden="true"></i>
              </Link>
            ) : (
              <Link
                className="developmentpagecurrentinvestmentviewalllink"
                data-bs-toggle="modal"
                data-bs-target="#signinModal"
                aria-haspopup="dialog"
                aria-controls="signinModal"
              >
                {Strings.viewAllProjects}
                <i className="fa-solid fa-chevron-right" aria-hidden="true"></i>
              </Link>
            )}
            <SigninRegister />
          </div>
        </section>

        {/* Investor relations section */}
        <section
          className="developmentpageinvestorbanner"
          aria-labelledby="investorRelationsHeader"
        >
          <div
            id="investorRelationsHeader"
            className="developmentpageinvestorbannersection"
          >
            <div className="developmentpageinvestorbannersectionhead">
              {Strings.investorRelations}
            </div>
            <div className="developmentpageinvestorbannersectiondesc">
              {Strings.investorRelationsDesc}
            </div>
          </div>
          <div className="developmentpageinvestorbannerbuild">
            "{Strings.buildFutureOne} <span>{Strings.buildFutureTwo}"</span>
          </div>
        </section>
      </main>
    </LayoutSecondary>
  );
};

export default Development;
