import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, forgotPassword } from "store/authSlice";
import Swal from "sweetalert2";
import loginlogo from "assets/images/SigninRegister/login.svg";
import "components/SigninRegister/SigninRegister.scss";
import Strings from "components/Constants/Strings";

export const SigninRegister = () => {
  // Login form state
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
    userType: "Investor",
    rememberMe: false,
  });

  // Forgot password form state
  const [forgotPasswordForm, setForgotPasswordForm] = useState({
    email: "",
  });

  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const dispatch = useDispatch();
  const { isLoading, error, message } = useSelector((state) => state.auth);

  // Reset form states on modal close
  const resetState = () => {
    setIsForgotPassword(false);
    setLoginForm({
      email: "",
      password: "",
      userType: "Investor",
      rememberMe: false,
    });
    setForgotPasswordForm({ email: "" });
  };

  useEffect(() => {
    const modalElement = document.getElementById("signinModal");

    // Reset state when the modal is hidden
    const handleModalHide = () => {
      resetState();
    };

    // Add event listener to modal
    modalElement.addEventListener("hide.bs.modal", handleModalHide);

    // Cleanup event listener on component unmount
    return () => {
      modalElement.removeEventListener("hide.bs.modal", handleModalHide);
    };
  }, []);

  // Handle login form submission
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(loginUser(loginForm)).unwrap();
      Swal.fire({
        title: "Success",
        text: "Login successful!",
        icon: "success",
      }).then(() => {
        document.getElementById("closePopup").click();
        resetState();
      });
    } catch (err) {
      Swal.fire({
        title: "Error",
        text: err || "Login failed. Please try again.",
        icon: "error",
      });
    }
  };

  // Handle forgot password form submission
  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(forgotPassword(forgotPasswordForm)).unwrap();
      // Swal.fire({
      //   title: "Success",
      //   text: "Password reset link sent to your email!",
      //   icon: "success",
      // });
      setIsForgotPassword(false);
    } catch (err) {
      Swal.fire({
        title: "Error",
        text: err || "Failed to send reset link. Please try again.",
        icon: "error",
      });
    }
  };

  return (
    <div
      className="sign-in modal fade"
      id="signinModal"
      tabIndex="-1"
      aria-labelledby="signinModalTitle"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <header className="modal-header">
            <h2 className="modal-title" id="signinModalTitle">
              {isForgotPassword
                ? Strings.forgotPasswordForm
                : Strings.signInRegister}
            </h2>
            <button
              id="closePopup"
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </header>
          <main className="modal-body">
            <div className="text-center">
              <img src={loginlogo} alt="Login Logo" />
            </div>

            {/* Login Form */}
            {!isForgotPassword && (
              <form onSubmit={handleLoginSubmit}>
                <fieldset>
                  <div className="mb-3">
                    <label htmlFor="userType" className="form-label">
                      {Strings.selectUserType}
                    </label>
                    <div className="d-flex gap-3">
                      {["Investor"].map((type) => (
                        <div className="form-check" key={type}>
                          <input
                            type="radio"
                            className="form-check-input"
                            id={type}
                            name="userType"
                            value={type}
                            checked={loginForm.userType === type}
                            onChange={(e) =>
                              setLoginForm({
                                ...loginForm,
                                userType: e.target.value,
                              })
                            }
                          />
                          <label className="form-check-label" htmlFor={type}>
                            {Strings[type.toLowerCase()]}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      {Strings.email}
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      value={loginForm.email}
                      onChange={(e) =>
                        setLoginForm({ ...loginForm, email: e.target.value })
                      }
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      {Strings.password}
                    </label>
                    <div className="input-group">
                      <input
                        type={showPassword ? "text" : "password"} // Toggle input type
                        className="form-control"
                        id="password"
                        value={loginForm.password}
                        onChange={(e) =>
                          setLoginForm({
                            ...loginForm,
                            password: e.target.value,
                          })
                        }
                        required
                      />
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={() => setShowPassword(!showPassword)} // Toggle visibility
                      >
                        <i class="fa-regular fa-eye"></i>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <input
                        type="checkbox"
                        id="rememberMe"
                        checked={loginForm.rememberMe}
                        onChange={(e) =>
                          setLoginForm({
                            ...loginForm,
                            rememberMe: e.target.checked,
                          })
                        }
                      />
                      <label htmlFor="rememberMe" className="ms-2">
                        {Strings.rememberMe}
                      </label>
                    </div>
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => setIsForgotPassword(true)}
                    >
                      {Strings.forgotMyPassword}
                    </button>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary w-100 mt-4"
                    disabled={isLoading}
                  >
                    {isLoading ? "Logging in..." : Strings.login}
                  </button>
                  {error && (
                    <div className="alert alert-danger mt-3">{error}</div>
                  )}
                </fieldset>
              </form>
            )}

            {/* Forgot Password Form */}
            {isForgotPassword && (
              <form onSubmit={handleForgotPasswordSubmit}>
                <fieldset>
                  <div className="mb-3">
                    <label htmlFor="forgotEmail" className="form-label">
                      {Strings.email}
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="forgotEmail"
                      value={forgotPasswordForm.email}
                      onChange={(e) =>
                        setForgotPasswordForm({
                          ...forgotPasswordForm,
                          email: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary w-100"
                    disabled={isLoading}
                  >
                    {isLoading ? "Sending..." : Strings.sendResetLink}
                  </button>
                  {message && (
                    <div className="alert alert-success mt-3">{message}</div>
                  )}
                  {error && (
                    <div className="alert alert-danger mt-3">{error}</div>
                  )}
                  <button
                    type="button"
                    className="btn btn-link mt-3"
                    onClick={() => setIsForgotPassword(false)}
                  >
                    {Strings.backToLogin}
                  </button>
                </fieldset>
              </form>
            )}
          </main>
        </div>
      </div>
    </div>
  );
};

export default SigninRegister;
