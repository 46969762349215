import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image1 from "assets/images/Development/completedProject1.png";
// import Image2 from "assets/images/Development/completedProject2.png";
// import Image3 from "assets/images/Development/completedProject3.png";
// import Image4 from "assets/images/Development/completedProjectpic1.png";
// import Image5 from "assets/images/Development/completedProjectpic2.png";
import Strings from "components/Constants/Strings";
import apiUrls from "components/Constants/ApiUrls";
import { getDevCompletedProperties } from "api/propertiesApi";

//Array of Property Details
// const sampleproperties = [
//   {
//     heroImage: Image1,
//     carouselImages: [Image4, Image5],
//     details: {
//       title: "Property 1",
//       description:
//         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.",
//       price: "$3000",
//       area: "Kansas City",
//       size: "1300 Sqft",
//       bedrooms: "5",
//       rooms: "3",
//       structure: "Wood",
//     },
//   },
//   {
//     heroImage: Image2,
//     carouselImages: [Image4, Image5],
//     details: {
//       title: "Property 2",
//       description:
//         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation",
//       price: "$3200",
//       area: "New York",
//       size: "1400 Sqft",
//       bedrooms: "4",
//       rooms: "2",
//       structure: "Brick",
//     },
//   },
//   {
//     heroImage: Image3,
//     carouselImages: [Image4, Image5],
//     details: {
//       title: "Property 3",
//       description:
//         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation",
//       price: "$2900",
//       area: "California",
//       size: "1200 Sqft",
//       bedrooms: "3",
//       rooms: "2",
//       structure: "Concrete",
//     },
//   },
// ];

const carouselSettings = {
  dots: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
};

const CompletedProjects = () => {
  const [properties, setProperties] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [selectedCarouselImages, setSelectedCarouselImages] = useState([]);

  //console.log("Completed Projects Properties", properties);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await getDevCompletedProperties();
        setProperties(response);
      } catch (error) {
        console.error("Error fetching completed projects:", error);
      }
    };

    fetchProperties();
  }, []);

  // Function to open modal with selected images
  const openImageModal = (images, index) => {
    setSelectedCarouselImages(images);
    setSelectedImageIndex(index);
  };

  // Function to close modal
  const closeImageModal = () => {
    setSelectedImageIndex(null);
    setSelectedCarouselImages([]);
  };

  // Function to navigate to the previous image
  const showPreviousImage = (e) => {
    e.stopPropagation();
    setSelectedImageIndex((prevIndex) =>
      prevIndex === 0 ? selectedCarouselImages.length - 1 : prevIndex - 1
    );
  };

  // Function to navigate to the next image
  const showNextImage = (e) => {
    e.stopPropagation();
    setSelectedImageIndex((prevIndex) =>
      prevIndex === selectedCarouselImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <section
      className="developmentpagecompletedprojects"
      aria-labelledby="completedProjectsHeader"
    >
      <h2
        id="completedProjectsHeader"
        className="developmentpagecompletedprojectshead"
      >
        {Strings.completedProjectsOne}{" "}
        <span>{Strings.completedProjectsTwo}</span>
      </h2>
      <div className="row">
        {properties.map((property, index) => {
          // Parse images from the property object
          let parsedImages = [];
          try {
            parsedImages = property.gallery_filenames
              ? property.gallery_filenames
                  .split(",")
                  .map((filename) => ({ filename }))
              : [];
          } catch (error) {
            console.error("Error parsing gallery_filenames:", error);
          }

          // Use the first image as heroImage and others for carousel
          const heroImage =
            parsedImages.length > 0 && parsedImages[0]?.filename
              ? `${apiUrls.imagesURL}${parsedImages[0].filename}`
              : Image1;

          const carouselImages = parsedImages
            .slice(1)
            .map((img) => `${apiUrls.imagesURL}${img.filename}`);

          return (
            <div
              key={index}
              className="col-12 col-md-4"
              aria-labelledby={`propertyName-${index}`}
            >
              {/* Hero Image */}
              <div className="developmentpagecompletedprojectshero">
                <img
                  src={heroImage}
                  alt={
                    property.propertyName || `Completed Project ${index + 1}`
                  }
                  loading="lazy"
                  onError={(e) => {
                    e.target.src = Image1;
                    console.error("Error loading hero image:", heroImage);
                  }}
                />
              </div>

              {/* Carousel */}
              <div
                className="developmentpagecompletedprojectscarousel"
                aria-label={`Image carousel for ${property.propertyName}`}
              >
                <Slider
                  {...{
                    ...carouselSettings,
                    arrows: carouselImages.length > 1,
                    infinite: carouselImages.length > 1,
                  }}
                >
                  {carouselImages.map((image, imgIndex) => (
                    <div
                      key={imgIndex}
                      onClick={() => openImageModal(carouselImages, imgIndex)}
                    >
                      <img
                        src={image}
                        alt="Completed projects carousel"
                        loading="lazy"
                        tabIndex={0}
                      />
                    </div>
                  ))}
                </Slider>

                {/* Fullscreen Modal */}
                {selectedImageIndex !== null && (
                  <div
                    className="developmentpagecompletedprojectscarouselfullscreenmodal"
                    onClick={closeImageModal}
                    aria-labelledby={`carouselModal-${index}`}
                  >
                    {/* Close Icon */}
                    <button
                      className="img-close"
                      onClick={(e) => {
                        e.stopPropagation();
                        closeImageModal();
                      }}
                      aria-label="Close image modal"
                    >
                      <i className="fas fa-times"></i>
                    </button>

                    {/* Previous Arrow */}
                    <button
                      className="img-arrow img-arrow-left"
                      onClick={(e) => {
                        e.stopPropagation();
                        showPreviousImage(e);
                      }}
                      aria-label="Previous image"
                    >
                      <i className="fas fa-chevron-left"></i>
                    </button>

                    {/* Image */}
                    <div className="fullscreen-image-wrapper">
                      <img
                        src={selectedCarouselImages[selectedImageIndex]}
                        alt="Fullscreen view"
                        className="fullscreen-image"
                      />
                    </div>

                    {/* Next Arrow */}
                    <button
                      className="img-arrow img-arrow-right"
                      onClick={(e) => {
                        e.stopPropagation();
                        showNextImage(e);
                      }}
                      aria-label="Next image"
                    >
                      <i className="fas fa-chevron-right"></i>
                    </button>
                  </div>
                )}
              </div>

              {/* Property Details */}

              <div className="developmentpagecompletedprojectsdetails">
                <div className="row developmentpagecompletedprojectsdetailstitle">
                  {property.propertyName}
                </div>
                <div className="row developmentpagecompletedprojectsdetailsdesc">
                  {property.propertyDesc}
                </div>
                <div className="row">
                  <div className="col developmentpagecompletedprojectsdetailsdescdata">
                    <div className="row">
                      {Strings.price} {property.projectPrice1}
                    </div>
                    <div className="row">
                      {Strings.area} {property.project_location_name}
                    </div>
                  </div>
                  <div className="col developmentpagecompletedprojectsdetailsdescdata">
                    <div className="row">
                      {Strings.size} {property.landArea}
                    </div>
                    <div className="row">
                      {Strings.bedrooms} {property.bedrooms}
                    </div>
                  </div>
                  <div className="col developmentpagecompletedprojectsdetailsdescdata">
                    <div className="row">
                      {Strings.rooms} {property.rooms}
                    </div>
                    <div className="row">
                      {Strings.structure} {property.project_structure_type_name}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default CompletedProjects;
