import React, { useState, useEffect } from "react";
import Layout from "components/Layouts/LayoutPrimary/LayoutPrimary";
import "components/About/About.scss";
import aboutUsBannerRight from "assets/images/About/aboutusbannerright.png";
import aboutUsStory from "assets/images/About/aboutusstory.png";
import team1 from "assets/images/About/emily.png";
import team2 from "assets/images/About/david.png";
import team3 from "assets/images/About/parker.png";
import mailIcon from "assets/images/About/mailicon.png";
import contactIcon from "assets/images/About/contacticon.png";
import Strings from "components/Constants/Strings";

// Array of objects
const teamMembers = [
  {
    id: 1,
    name: "Emily Gros Naccarato",
    position: "President, LDG Development",
    email: "test1@abc.com",
    image: team1,
    description:
      "Emily Gros Naccarato, serves as President of LDG Development and a founding partner of the LDG Group.  Emily has 20+ years of  experience in the pharmaceutical industry and was an executive leader managing multi-million-dollar projects, large scale global teams of over 100 people, vendors and multi-year and phased complex timelines; including all aspects of resource, supply, cost and demand management. Additionally, she led the teams that performed several build out/construction projects and personally managed the complete remodel of homes in New Jersey, Illinois and Florida.  Emily holds a BS in Industrial Engineering from Syracuse University and an MBA from the University of New Hampshire.  She holds Executive MBA certification from UPenn the Wharton School in Organizational Design and Change Management and Northwestern Kellogg School of Management in Pipeline Development.",
  },
  {
    id: 2,
    name: "David Naccarato",
    position: "President,LDG Building & Construction",
    email: "test2@abc.com",
    image: team2,
    description:
      "David Naccarato, serves as President of Lucky Duck Builders and a founding partner of the LDG Group.  David has 20+ years as a finance and accounting professional leading large teams and vendors in system implementations across multiple highly regulated industries.  He has completed several extensive renovations for single family homes and rental properties in California, Illinois and Florida.  David holds a BS in Economics from UC Santa Barbara and an MBA from USC Marshall School of Business. ",
  },
  {
    id: 3,
    name: "Parker Mercer",
    position: "Associate, LDG Development",
    email: "test3@abc.com",
    image: team3,
    description:
      "Parker Mercer serves as an Associate in the LDG Development division.  Parker is a proud graduate of James Madison University (Go Dukes!) where he received a B.S. in Psychology and William & Mary's School of Education where he earned a Master of Education in Policy, Planning & Leadership in Higher Education Administration.  In his free time, he enjoys traveling and spending time with my niece, nephews, and cousins. ",
  },
];

const About = () => {
  const [selectedMember, setSelectedMember] = useState(null);

  // Function to open the modal with the selected member's details
  const openModal = (member) => {
    setSelectedMember(member); // Set selected member in state
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedMember(null); // Reset selected member
  };

  // Handle modal show behavior using useEffect
  useEffect(() => {
    if (selectedMember) {
      const modalElement = document.getElementById("teamcontact1");

      // Only show the modal if it exists and selectedMember is set
      if (modalElement) {
        const modal = new window.bootstrap.Modal(modalElement);
        modal.show(); // Show the modal
      }
    }
  }, [selectedMember]); // This effect runs only when selectedMember changes

  return (
    <Layout>
      <main className="aboutuspage">
        {/* Banner Section */}
        <section
          className="aboutuspagebanner"
          role="banner"
          aria-labelledby="about-banner-title"
        >
          {Strings.aboutUs}
          <div className="ms-auto d-none d-md-block">
            <img src={aboutUsBannerRight} alt="About us banner right" />
          </div>
        </section>

        {/* Story Section */}
        <section
          className="aboutuspagestory"
          aria-labelledby="about-story-title"
        >
          <div className="row align-items-center justify-content-center">
            <div className="col text-center">
              <div className="aboutuspagestoryimgright">
                <img src={aboutUsStory} alt="About us our story" />
              </div>
            </div>
            <div className="col">
              <div className="aboutuspagestoryhead">
                {Strings.ourStoryOne} <span>{Strings.ourStoryTwo}</span>
              </div>
              <div className="aboutuspagestorydesc">{Strings.ourStoryDesc}</div>
            </div>
          </div>
        </section>

        {/* Mission Section */}
        <section
          className="aboutuspagemission"
          aria-labelledby="about-mission-title"
        >
          <div className="aboutuspagemissionhead">{Strings.ourMission}</div>
          <div className="aboutuspagemissiondesc">{Strings.ourMissionDesc}</div>
        </section>

        {/* Build Future Section */}
        <section
          className="aboutuspagebuild"
          aria-labelledby="about-build-future-title"
        >
          "{Strings.buildFutureOne} <span>{Strings.buildFutureTwo}"</span>
        </section>

        {/* Team Section */}
        <section className="aboutuspageteam" aria-labelledby="about-team-title">
          <div className="aboutuspageteamhead">{Strings.meetTheTeam}</div>
          <div className="row">
            {teamMembers.map((member) => (
              <div
                className="col text-center"
                key={member.id}
                aria-labelledby={`team-member-${member.id}`}
              >
                <div className="aboutuspageteaminfo">
                  <img src={member.image} alt={member.name} />
                  <div className="aboutuspageteamdetail">
                    <div className="row">
                      <div className="col-8">
                        <div className="aboutuspageteamdetailname">
                          {member.name}
                        </div>
                        <div className="aboutuspageteamdetailpost">
                          {member.position}
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="aboutuspageteamdetailicons">
                          <img
                            src={contactIcon}
                            alt="Contact"
                            onClick={() => openModal(member)}
                            className="pointer"
                            aria-label={`More information about ${member.name}`}
                          />
                          <a
                            href={`mailto:${member.email}`}
                            aria-label={`Send email to ${member.name}`}
                          >
                            <img
                              src={mailIcon}
                              alt="Mail"
                              className="pointer"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>

        {selectedMember && (
          <div
            className="modal fade"
            id="teamcontact1"
            tabIndex="-1"
            aria-labelledby="teamcontact1Label"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{selectedMember.name}</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeModal}
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="row align-items-center">
                    <div className="col-4">
                      <div className="aboutuspageteammember">
                        <img
                          className="aboutuspageteammember"
                          src={selectedMember.image}
                          alt={selectedMember.name}
                        />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="aboutuspageteammemberdesc">
                        {selectedMember.description}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </Layout>
  );
};

export default About;
