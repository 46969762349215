import React from "react";
import Header from "components/Headers/HeaderPrimary/HeaderPrimary";
import "components/Headers/HeaderPrimary/HeaderPrimary.scss";
import "components/Footer/Footer.scss";
import Footer from "components/Footer/Footer";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import ScrollToTopDefault from "components/Layouts/LayoutPrimary/ScrollToTopDefault"

export default function LayoutPrimary({ children }) {
  return (
    <div>
      <ScrollToTopDefault />
      <ScrollToTop />
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
}
