import Strings from "components/Constants/Strings";

export const handleApiError = (error) => {
  const { response } = error;

  if (!response) {
    console.error("Network error:", error.message);
    return Strings.networkError;
  }

  console.error(`API Error [${response.status}]:`, response.data);
  switch (response.status) {
    case 400:
      return (
        Strings.badRequestError || "Bad request. Please verify your input."
      );
    case 401:
      return Strings.unauthorizedError || "Unauthorized. Please login.";
    case 403:
      return Strings.forbiddenError || "Forbidden. You don't have permission.";
    case 404:
      return Strings.notFoundError || "Resource not found.";
    case 429:
      return (
        Strings.tooManyRequestsError ||
        "Too many requests. Please try again later."
      );
    case 500:
      return Strings.serverError || "Server error. Please try again later.";
    default:
      return (
        Strings.unexpectedError ||
        "An unexpected error occurred. Please try again."
      );
  }
};
